@if (origin && (!!title || !!text)) {
  <glxy-popover
    [origin]="origin"
    [isOpen]="show"
    [positions]="positions"
    [hasArrow]="true"
    [padding]="title ? 'large' : 'small'"
    [highContrast]="highContrast"
  >
    @if (title) {
      <glxy-popover-title>
        {{ title | translate }}
      </glxy-popover-title>
    }
    @if (text) {
      <span>{{ text | translate }}</span>
    }
  </glxy-popover>
}

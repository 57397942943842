import { inject, InjectionToken } from '@angular/core';
import { ChildActivationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Package } from '@vendasta/marketplace-packages';
import { SalesPerson } from '@vendasta/snapshot';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, publishReplay, refCount, skipWhile, tap } from 'rxjs/operators';
import { PartnerService } from '../partner/partner.service';
import { WhitelabelService } from '../whitelabel/whitelabel.service';

export const PARTNER_ID_TOKEN = 'PARTNER_ID_TOKEN';
export const getPartnerIdFromService = (partnerService: PartnerService) => {
  return partnerService.getPartnerId();
};

export const SNAPSHOT_NAME_TOKEN = 'SNAPSHOT_NAME_TOKEN';
export const getSnapshotName = (whitelabelService: WhitelabelService): Observable<string> => {
  return whitelabelService.snapshotReportName$.pipe(map((s) => (s ? s : 'TITLES.SNAPSHOT_REPORT')));
};

export function translationsAppInitializerFactory(translate: TranslateService): () => void {
  return () => {
    translate.setDefaultLang('en');
    translate.use('en');
  };
}

export type RouteGeneratorParams =
  | { routeType: RouteType.View | RouteType.Edit | RouteType.Share | RouteType.EditV2; snapshotId: string }
  | { routeType: RouteType.Error }
  | { routeType: RouteType.Lite; businessId: string };

export enum RouteType {
  View,
  Edit,
  Share,
  Lite,
  Error,
  EditV2,
}

export interface RoutingServiceInterface {
  getRoute: (RouteGeneratorParams) => string | undefined;
  isOldEditRoute?: (route: string) => boolean;
}

export const ROUTE_GENERATOR_TOKEN = new InjectionToken<RoutingServiceInterface>('[snapshot]: Token for edit route', {
  providedIn: 'root',
  factory: () => {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      getRoute(_: RouteGeneratorParams): string | undefined {
        return undefined;
      },
    };
  },
});

export const SNAPSHOT_ID_TOKEN = new InjectionToken<Observable<string | undefined>>(
  '[Snapshot]: Token for snapshot id',
  {
    providedIn: 'root',
    factory: function (): Observable<string | undefined> {
      const router = inject(Router);
      return router.events.pipe(
        filter((e) => e instanceof ChildActivationStart),
        map((e: ChildActivationStart) => e.snapshot.children[0].params),
        map((p) => {
          if ('snapshotId' in p) {
            return p['snapshotId'];
          }
          return;
        }),
        skipWhile((a) => !a),
        distinctUntilChanged(),
        tap((id) => {
          this.snapshotId = id;
        }),
        publishReplay(1),
        refCount(),
      );
    },
  },
);

export const TechnologySectionExistsToken = new InjectionToken<Observable<boolean>>('Technology section exists');

export interface SnapshotFeatureFlags {
  unset?: boolean; // placeholder so we don't have an empty interface when no FF are set
  printReport?: boolean;
}
export const SnapshotFeatureFlagsToken = new InjectionToken<Observable<SnapshotFeatureFlags>>(
  'Snapshot active feature flags',
  { factory: () => of({}) },
);

export const SalesPersonToken = new InjectionToken<Observable<SalesPerson | undefined>>(
  'SalesPerson for the current snapshot',
  { factory: () => of(undefined) },
);

export interface Media {
  mobile$: Observable<boolean>;
  print$: Observable<boolean>;
}
export const MediaToken = new InjectionToken<Media>('Media match for the layout', {
  factory: () => ({
    mobile$: of(false),
    print$: of(false),
  }),
});

export interface MarketplaceServiceInterface {
  activatablePackages$: Observable<Package[]>;
  packageUrl$: (packageId: string) => Observable<string>;
}
export const MarketplaceServiceToken = new InjectionToken<MarketplaceServiceInterface>('MarketplaceService');

import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Environment, EnvironmentService } from '@galaxy/core';
import { AuthConfig, OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { distinctUntilChanged, filter, map, mapTo, shareReplay, startWith } from 'rxjs/operators';

// The config provided by users of the Auth Service for their app.
export interface OAuth2ServiceConfig {
  // List of scopes which will be requested
  scopes: string[];

  // You may optionally pass a custom handler for when scopes are requested but are denied.
  // The default handler simply pops an error snack bar
  missingConsentForScopesHandler?: (err: OAuthErrorEvent) => void;

  // A map of environment to the service provider config to use
  serviceProviderConfigs: ServiceProviderEnvConfig;

  oauthConfig?: AuthConfig;

  // optional callback that receives the same args as a canActivate() guard, and returns any custom
  // query params to pass through to SSO (if needed). For example: partner_id, account_id.
  //
  // This may be omitted if you don't need to set any SSO query params, but most apps will need to
  // specify at least a partner_id or account_id, for Platform and Product service providers,
  // respectively.
  customQueryParamsFunc?: (next: ActivatedRouteSnapshot, snapshot: RouterStateSnapshot) => any;
}

export interface ServiceProviderEnvConfig {
  [env: number]: ServiceProviderConfig;
}

// Configure your service at:
// https://admin.vendasta-internal.com/integrations
export interface ServiceProviderConfig {
  // sso serviceProviderId
  serviceProviderId: string;
  // sso oauth2 client id
  clientId: string;
  // URL to redirect back into your app at.
  redirectUri: string;
  // See README for more information
  silentRefreshRedirectUri: string;
}

export const OAuth2ServiceConfigToken = new InjectionToken<OAuth2ServiceConfig>('AuthServiceConfig');

interface Claim {
  sub: string;
}

class State {
  constructor(public readonly nextUrl: string) {}

  static decode(state: string): State {
    return JSON.parse(atob(decodeURIComponent(state)));
  }

  encode(): string {
    return btoa(JSON.stringify(this));
  }
}

interface VendastaAuthConfig extends AuthConfig {
  issuer: string;
  redirectUri: string;
  silentRefreshRedirectUri: string;
  clientId: string;
  logoutUrl: string;
}

// the default config passed to the OIDC library. It can be customized by providing a OAuth2ServiceConfig.oauthConfig from your app.
const baseAuthConfig: AuthConfig = {
  // This is required because IAM and SSO serve different parts of the oauth2 workflow
  // IAM issues the tokens but sso handles auth and exchange of codes for tokens
  strictDiscoveryDocumentValidation: false,

  // response type must be 'code' for PKCE flow
  responseType: 'code',

  // use silent refresh rather than refresh token
  useSilentRefresh: true,

  // timeoutFactor: 0.5, // % of token life to wait before refreshing. The default is 0.75.
  // showDebugInformation: true,
};

declare let environment: string;

@Injectable({ providedIn: 'root' })
export class OAuth2Service {
  public readonly userId$: Observable<string> = this.oauthService.events.pipe(
    map(() => this.getUserId()),
    distinctUntilChanged(),
    shareReplay(1),
  );

  private readonly accessToken$$ = new BehaviorSubject<string>(null);
  private discoveryLoaded$: Observable<boolean>;

  private get authConfig(): AuthConfig {
    let env = this.environmentService.getEnvironment();
    // I manually check the environment variable, the rest of the app is set to
    // pull data from demo, but I need to redirect back to localhost for auth.
    if (typeof environment === 'undefined') {
      env = Environment.LOCAL;
    }
    const envConfig = this.getConfig(env);
    const oauthConfig = this.authServiceConfig.oauthConfig || {};
    const scope = this.authServiceConfig.scopes.join(' ');

    return { ...baseAuthConfig, ...oauthConfig, ...envConfig, scope: scope };
  }

  constructor(
    private oauthService: OAuthService,
    private environmentService: EnvironmentService,
    private router: Router,
    @Inject(OAuth2ServiceConfigToken) private authServiceConfig: OAuth2ServiceConfig,
  ) {
    this.oauthService.configure(this.authConfig);

    // if catch is to be implemented it should handle both normal error flows, and error events spawned from the playground appropriately in regard to each
    this.discoveryLoaded$ = from(
      this.oauthService.loadDiscoveryDocumentAndTryLogin().catch((e) => {
        console.error('loadDiscoveryDocumentAndTryLogin: ', e);
      }),
    ).pipe(mapTo(true), shareReplay(1));

    // Setting up refresh first allows a refresh attempt to occur before exposing user to login
    this.oauthService.setupAutomaticSilentRefresh();

    this.oauthService.events
      .pipe(
        filter((e) => e.type === 'token_received' || e.type === 'token_refreshed'),
        map(() => this.oauthService.getAccessToken()),
        startWith(this.oauthService.getAccessToken()),
        filter((sessionId) => !!sessionId),
        distinctUntilChanged(),
      )
      .subscribe(this.accessToken$$);

    this.oauthService.events
      .pipe(filter((e) => e.type === 'silent_refresh_error'))
      .subscribe(this.tokenRefreshHandler.bind(this));
  }

  private tokenRefreshHandler(err: OAuthErrorEvent): void {
    if (
      err.reason instanceof OAuthErrorEvent &&
      err.reason.params &&
      (err.reason.params['error'] === 'login_required' || err.reason.params['error'] === 'consent_required')
    ) {
      const fn = this.authServiceConfig.missingConsentForScopesHandler || this.defaultTokenRefreshHandler;
      fn(err);
    } else {
      this.tokenRefreshFailureHandler(err);
    }
  }

  // defaultTokenRefreshHandler runs when the error was due to lack of scopes, merely informs the user by default
  // can be override by setting customTokenRefreshHandler
  private defaultTokenRefreshHandler(err: OAuthErrorEvent): void {
    console.error('defaultTokenRefreshHandler: ', err);
  }

  // tokenRefreshFailureHandler runs when there was an non-scope error
  private tokenRefreshFailureHandler(err: OAuthErrorEvent): void {
    console.error('tokenRefreshFailureHandler: ', err);
  }

  // logout clears local app data and continues to registered logout url
  public logout(customLogoutURLParams?: object): void {
    this.oauthService.logOut(customLogoutURLParams);
  }

  // localLogout only clears the local app data (no logout url)
  public localLogout(): void {
    const noRedirectToLogoutUrl = true;
    this.oauthService.logOut(noRedirectToLogoutUrl);
  }

  public login(): void {
    const state = new State(this.router.routerState.snapshot.url);
    this.oauthService.initLoginFlow(state.encode());
  }

  private getUserId(): string {
    const claims = this.oauthService.getIdentityClaims() as Claim;
    if (!claims) {
      return '';
    }
    return claims.sub;
  }

  public canActivateChild(next: ActivatedRouteSnapshot, snapshot: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(next, snapshot);
  }

  public canActivate(next: ActivatedRouteSnapshot, snapshot: RouterStateSnapshot): Observable<boolean> {
    return this.discoveryLoaded$.pipe(
      map(() => {
        if (next.queryParams.error) {
          // if we were redirected back with an error, don't try to redirect out again
          return true;
        }

        if (this.authServiceConfig.customQueryParamsFunc) {
          this.oauthService.customQueryParams = this.authServiceConfig.customQueryParamsFunc(next, snapshot);
        }

        // if we have code+state query params, even if we don't yet have a valid token we should redirect to the nextURL
        // so we don't end up in an infinite recursive loop.
        if (!!next.queryParams.code && !!this.oauthService.state) {
          const stateEncoded = this.oauthService.state;
          let nextURL: string;
          try {
            const state = State.decode(stateEncoded);
            nextURL = state.nextUrl;
          } catch (e) {
            console.warn(`failed to decode State from ${stateEncoded}`, e);
          }

          if (nextURL) {
            this.router.navigateByUrl(nextURL);
          }

          return true;
        }

        if (!this.oauthService.hasValidAccessToken()) {
          // save nextUrl for when we come back from auth redirect
          const state = new State(snapshot.url);
          this.oauthService.initCodeFlow(state.encode());
          return false;
        }

        return true;
      }),
    );
  }

  public getSessionId(): Observable<string> {
    // AccessToken is the 'session id'.
    return this.accessToken$$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public setSessionId(_: string): void {
    // placeholder to match SessionServiceInterface. The auth token is automatically refreshed via oauth and doesn't
    // need to explicitly call setSessionId(), but atlas lib refreshes an undefinedtoken on startup even without specifying a [token] input.
  }

  getConfig(env: Environment): VendastaAuthConfig {
    const { serviceProviderId, clientId, redirectUri, silentRefreshRedirectUri } =
      this.authServiceConfig.serviceProviderConfigs[env];

    const { iamHost, ssoHost, vlcHost } =
      env === Environment.PROD
        ? {
            iamHost: 'https://iam-prod.apigateway.co',
            ssoHost: 'https://sso-api-prod.apigateway.co',
            vlcHost: 'https://login-prod.apigateway.co',
          }
        : {
            iamHost: 'https://iam-demo.apigateway.co',
            ssoHost: 'https://sso-api-demo.apigateway.co',
            vlcHost: 'https://login-demo.apigateway.co',
          };

    const accountSelectorQuery = new URLSearchParams({
      serviceProviderId: serviceProviderId,
    });
    const accountSelectorURL = `${vlcHost}/account-selector/login?${accountSelectorQuery.toString()}`;

    const logoutRedirectQuery = new URLSearchParams({
      next_url: accountSelectorURL,
    });
    const logoutQuery = new URLSearchParams({
      nextURL: `${vlcHost}/logout-redirect?${logoutRedirectQuery.toString()}`,
    });

    return {
      issuer: iamHost,
      logoutUrl: `${ssoHost}/oauth2/logout?${logoutQuery.toString()}`,
      redirectUri,
      silentRefreshRedirectUri,
      clientId,
    };
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Addon,
  AppType,
  CreateAppRequest,
  CreateAppRequestInterface,
  FieldMask,
  GetAddonRequest,
  GetAppIDFromSkuRequest,
  GetAppIDFromSkuResponse,
  GetAppRequest,
  GetMultiAppRequest,
  GetMultiProductCostRequest,
  ListAppsForVendorIDsRequest,
  ListAppsForVendorIDsResponse,
  ListAppsRequest,
  ListAppsRequestListAppsFilter,
  ListRequiredAppSummariesFilters,
  ListRequiredAppSummariesRequest,
  ListRequiredAppSummariesResponse,
  MarketplaceApp,
  MarketplaceAppsApiService,
  ProductCost,
  ReleaseType,
  RequestAppReviewRequest,
  StateFilterOptions,
  SuspendAppRequest,
  UnsuspendAppRequest,
  UpdateAppFulfillmentIntegrationRequest,
} from '@vendasta/marketplace-apps/v1';
import { HostService } from '@vendasta/marketplace-apps/v1';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

interface UploadFileResponseInterface {
  fileUrl?: string;
}

export interface SkuIds {
  appId?: string;
  addonId?: string;
  editionId?: string;
}

@Injectable({ providedIn: 'root' })
export class MarketplaceAppService {
  constructor(
    private apiService: MarketplaceAppsApiService,
    private http: HttpClient,
    private hostService: HostService,
  ) {}

  // getApp gets the app with the given id
  getApp(appId: string, fieldMask?: FieldMask): Observable<MarketplaceApp | null> {
    const request = new GetAppRequest({ appId: appId, fieldMask: fieldMask });
    const response = this.apiService.getApp(request);
    return response.pipe(
      map((resp) => {
        return resp ? resp.app : null;
      }),
    );
  }

  // getMultiApp gets the aps with the id. If a given id was not found, that entry in the returned list will be null
  getMultiApps(appIds: string[], fieldMask?: FieldMask): Observable<(MarketplaceApp | null)[]> {
    // Split appIds into chunks to prevent timeouts when there are hundreds of apps to load
    // https://vendasta.jira.com/browse/QOF-1293
    const chunkSize = 250;
    const chunks: string[][] = [];

    for (let i = 0; i < appIds.length; i += chunkSize) {
      chunks.push(appIds.slice(i, i + chunkSize));
    }

    const chunks$ = chunks.map((appIds) => {
      const request = new GetMultiAppRequest({ appId: appIds, fieldMask: fieldMask });
      return this.apiService.getMultiApp(request).pipe(
        map((resp) => {
          if (resp && resp.apps && resp.apps.length > 0) {
            return resp.apps;
          }
          return [];
        }),
      );
    });

    return forkJoin(chunks$).pipe(
      // flatten the chunks into a single array of apps
      map((chunks) => {
        return chunks.flat();
      }),
    );
  }

  // getAppDraft returns the current unpublished version of an app
  getAppDraft(appId: string, fieldMask?: FieldMask): Observable<MarketplaceApp | undefined> {
    const request = new GetAppRequest({ appId: appId, fieldMask: fieldMask });
    const response = this.apiService.getAppDraft(request);
    return response.pipe(
      map((resp) => {
        return resp ? resp.app : undefined;
      }),
    );
  }

  getAddon(appId: string, addonId: string): Observable<Addon | undefined> {
    const request = new GetAddonRequest({ appId: appId, addonId: addonId });
    const response$ = this.apiService.getAddon(request);
    return response$.pipe(
      map((resp) => {
        return resp ? resp.addon : undefined;
      }),
    );
  }

  listApps(
    pageSize = 0,
    cursor = '',
    fieldMask?: FieldMask,
    partnerId?: string,
    filterTerm?: string,
    releaseType?: ReleaseType,
    appType?: AppType,
    stateFilter?: StateFilterOptions,
  ): Observable<{
    apps: MarketplaceApp[];
    cursor: string;
    hasMore: boolean;
  }> {
    const filter = new ListAppsRequestListAppsFilter({
      partnerId: partnerId,
      filterTerm: filterTerm,
      releaseType: releaseType,
      appType: appType,
      state: stateFilter,
    });
    const request = new ListAppsRequest({
      pageSize: pageSize,
      cursor: cursor,
      fieldMask: fieldMask,
      filter: filter,
    });
    const response = this.apiService.listApps(request);
    return response.pipe(
      map((resp) => {
        if (!resp) {
          return { apps: [], cursor: '', pageSize: 0, hasMore: false };
        }
        return { apps: resp.apps, cursor: resp.cursor, pageSize: resp.pageSize, hasMore: resp.hasMore };
      }),
    );
  }

  listAppsForVendorIDs(
    vendorIds: string[],
    includeAddons: boolean,
    cursor = '',
    pageSize = 10,
    fieldMask?: FieldMask,
  ): Observable<{
    apps: MarketplaceApp[];
    cursor: string;
    hasMore: boolean;
  }> {
    const request = new ListAppsForVendorIDsRequest({
      vendorIds: vendorIds,
      includeAddons: includeAddons,
      cursor: cursor,
      pageSize: pageSize,
      fieldMask: fieldMask,
    });
    const response = this.apiService.listAppsForVendorIDs(request);
    return response.pipe(
      map((resp: ListAppsForVendorIDsResponse) => {
        if (!resp) {
          return { apps: [], cursor: '', hasMore: false };
        }
        return { apps: resp.apps, cursor: resp.cursor, hasMore: resp.hasMore };
      }),
    );
  }

  // This method will give you back the first parent of the app corresponding to the sku provided
  /**
   * @deprecated use the `appId` field on the response of the getIdsFromSku() method.
   */
  getAppIdFromSku(sku: string): Observable<string> {
    const request = new GetAppIDFromSkuRequest({ sku: sku });
    return this.apiService.getAppIdFromSku(request).pipe(map((resp: GetAppIDFromSkuResponse) => resp.appId));
  }

  getIdsFromSku(sku: string): Observable<SkuIds | null> {
    const request = new GetAppIDFromSkuRequest({ sku: sku });
    return this.apiService
      .getAppIdFromSku(request)
      .pipe(map((resp: GetAppIDFromSkuResponse) => (resp ? { ...resp } : null)));
  }

  // createApp sends an app creation request and returns the app
  createApp(kwargs: CreateAppRequestInterface): Observable<MarketplaceApp | null> {
    const request = new CreateAppRequest(kwargs);
    const response = this.apiService.createApp(request);
    return response.pipe(
      map((resp) => {
        return resp ? resp.app : null;
      }),
    );
  }

  // duplicateApp will duplicate the provided App and return the new App's ID
  duplicateApp(appId: string): Observable<string | null> {
    return this.apiService.duplicateApp({ appId: appId }).pipe(map((resp) => (resp ? resp.appId : null)));
  }

  // uploadMarketingFile uploads a file to be used for an apps marketing material
  // returns the url to view that file
  uploadMarketingFile(file: File): Observable<string | undefined> {
    const url = this.hostService.hostWithScheme + '/upload-file';
    return this.http
      .post<UploadFileResponseInterface>(url, file, {
        headers: new HttpHeaders({
          'Content-Type': file.type,
          'Content-Disposition': `attachment; filename="${file.name}"`,
        }),
        withCredentials: true,
      })
      .pipe(
        map((resp) => {
          return resp ? resp.fileUrl : undefined;
        }),
      );
  }

  // getMultiProductCost gets the cost of products for a partner.
  getMultiProductCost(partnerId: string, appIds: string[]): Observable<{ [key: string]: ProductCost } | null> {
    const request = new GetMultiProductCostRequest({ partnerId: partnerId, appIds: appIds });
    const response = this.apiService.getMultiProductCost(request);
    return response.pipe(
      map((resp) => {
        if (resp) {
          return resp.productCost;
        }

        return null;
      }),
    );
  }

  suspendApp(appId: string): Observable<boolean> {
    const req = new SuspendAppRequest({
      appId: appId,
    });
    return this.apiService.suspendApp(req).pipe(map(() => true));
  }

  unsuspendApp(appId: string): Observable<boolean> {
    const req = new UnsuspendAppRequest({
      appId: appId,
    });
    return this.apiService.unsuspendApp(req).pipe(map(() => true));
  }

  requestAppReview(appId: string): Observable<boolean> {
    const req = new RequestAppReviewRequest({
      appId: appId,
    });
    return this.apiService.requestAppReview(req).pipe(map(() => true));
  }

  updateAppFulfillmentIntegration(
    appId: string,
    usesFulfillmentIntegration: boolean,
  ): Observable<MarketplaceApp | null> {
    const request = new UpdateAppFulfillmentIntegrationRequest({
      appId: appId,
      usesFulfillmentIntegration: usesFulfillmentIntegration,
    });
    return this.apiService.updateAppFulfillmentIntegration(request).pipe(
      map((resp) => {
        return resp ? resp.app : null;
      }),
    );
  }

  listRequiredAppSummaries(
    vendorId: string,
    cursor: string,
    pageSize: number,
    filters: ListRequiredAppSummariesFilters,
  ): Observable<ListRequiredAppSummariesResponse> {
    return this.apiService.listRequiredAppSummaries(
      new ListRequiredAppSummariesRequest({
        vendorId,
        cursor,
        pageSize,
        filters,
      }),
    );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { VaBadgeModule } from '@vendasta/uikit';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CategoryOverviewComponent } from './category-overview/category-overview.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BusinessCategoryService } from './business-category.service';
import { GalaxyInfiniteScrollTriggerModule } from '@vendasta/galaxy/infinite-scroll-trigger';
import { HighlightSearchPipe } from './pipes/highlight-search.pipe';
import { CategorySelectComponent } from './category-select/category-select.component';
import { MatChipsModule } from '@angular/material/chips';
import { ExcludeCategoriesPipe } from './pipes/exclude-categories.pipe';
import { ChipColorPipe } from './pipes/chip-color.pipe';
import { GalaxyLoadingSpinnerModule } from '@vendasta/galaxy/loading-spinner';
import { GalaxyFormFieldModule } from '@vendasta/galaxy/form-field';
import { CategorySelectService } from './category-select/category-select.service';

@NgModule({
  declarations: [
    CategoryOverviewComponent,
    HighlightSearchPipe,
    CategorySelectComponent,
    ExcludeCategoriesPipe,
    ChipColorPipe,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    VaBadgeModule,
    MatProgressSpinnerModule,
    GalaxyInfiniteScrollTriggerModule,
    MatChipsModule,
    GalaxyLoadingSpinnerModule,
    GalaxyFormFieldModule,
  ],
  providers: [BusinessCategoryService, CategorySelectService],
  exports: [CategoryOverviewComponent, CategorySelectComponent],
})
export class BusinessCategoryModule {}

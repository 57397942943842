<div class="notifications-title">
  <div class="notifications-heading">
    {{ 'NOTIFICATIONS.TITLE' | translate }}
  </div>
  <div class="atlas-navbar__notifications__settings">
    <button mat-icon-button [atlasTooltip]="'NOTIFICATIONS.ACTIONS.SETTINGS' | translate" (click)="openSettings()">
      <mat-icon>settings</mat-icon>
    </button>
    <button
      mat-icon-button
      [atlasTooltip]="'NOTIFICATIONS.ACTIONS.MARK_ALL_AS_READ' | translate"
      (click)="markAllAsFollowedEvent()"
    >
      <mat-icon>drafts</mat-icon>
    </button>
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-divider></mat-divider>
@if (showNotificationsDisabledBanner()) {
  <glxy-alert
    type="tip"
    size="small"
    [actionTitle]="'NOTIFICATIONS.ALLOW_PUSH_NOTIFICATIONS' | translate"
    [borderRadius]="false"
    [showAction]="true"
    (actionClick)="openAppSettings()"
  >
    {{ 'NOTIFICATIONS.MOBILE_NOTIFICATIONS_DISABLED' | translate }}
  </glxy-alert>
}
<div class="atlas-navbar__notifications__container">
  <ng-content></ng-content>
</div>

import { EventEmitter, Injectable } from '@angular/core';
import {
  CategoriesApiService,
  Category,
  CategoryInterface,
  CategoryRequestInterface,
  CategoryType,
  CategoryUtils,
  PagedRequestOptionsInterface,
  SortDirection,
  SortField,
  SortOptionsInterface,
} from '@vendasta/category';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CATEGORY_SEARCH_PAGE_SIZE } from '../constants';
import { SearchResult } from '../interfaces';

@Injectable()
export class CategorySelectService {
  private readonly defaultPagingOptions: PagedRequestOptionsInterface = { pageSize: CATEGORY_SEARCH_PAGE_SIZE };
  private readonly defaultSortOptions: SortOptionsInterface[] = [
    { field: SortField.SORT_FIELD_SCORE, direction: SortDirection.SORT_DIRECTION_DESCENDING },
    { field: SortField.SORT_FIELD_FULL_NAME, direction: SortDirection.SORT_DIRECTION_ASCENDING },
  ];

  private readonly categorySearchTerm$$ = new ReplaySubject<string>(1);
  private readonly categorySearchTerm$ = this.categorySearchTerm$$.pipe(
    startWith(''),
    map((term) => term?.trim()),
    distinctUntilChanged(),
    debounceTime(200),
    tap(() => {
      this.aggregatedResult$$.next(null);
      this.categorySearchPage$$.next(this.defaultPagingOptions);
      this.isInitialFetch = true;
    }),
  );

  readonly language$$ = new BehaviorSubject<string>(null);

  private readonly categorySearchPage$$ = new ReplaySubject<PagedRequestOptionsInterface>(1);
  private readonly categorySearchPage$ = this.categorySearchPage$$.pipe(
    startWith(this.defaultPagingOptions),
    distinctUntilChanged(),
  );

  private readonly categoryType$$ = new BehaviorSubject<CategoryType>(CategoryType.CATEGORY_TYPE_V_CATEGORY);
  private readonly categoryType$ = this.categoryType$$.pipe(
    distinctUntilChanged(),
    tap(() => this.aggregatedResult$$.next(null)),
  );
  private readonly aggregatedResult$$ = new ReplaySubject<Category[]>(1);

  private nextCategoriesPage: PagedRequestOptionsInterface;
  private isInitialFetch = false;

  readonly afterInitialFetch: EventEmitter<void> = new EventEmitter();

  readonly categories$: Observable<SearchResult> = combineLatest([
    this.categorySearchTerm$,
    this.categorySearchPage$,
    this.categoryType$,
    this.language$$,
  ]).pipe(
    tap(() => (this.nextCategoriesPage = null)),
    switchMap(([searchTerm, pagingOptions, type, language]) => {
      if (isExternalId(searchTerm)) {
        return this.categoriesApiService
          .getCategoryByExternalIDsAndType({
            categories: [
              {
                externalId: searchTerm,
                categoryType: type,
              },
            ],
            languageAndLocale: CategoryUtils.localeFromLanguage(language),
          })
          .pipe(
            map((response) => {
              const categories = response?.category || [];
              // enforce the category type because the API will return null for vCategory
              categories.forEach((category) => (category.type = type));
              return <SearchResult>{ categories, searchTerm };
            }),
          );
      }
      return this.categoriesApiService
        .getCategoryBySearchTerm({
          searchTerm,
          type,
          pagingOptions,
          sortOptions: this.defaultSortOptions,
          onlyVisible: true,
          languageAndLocale: CategoryUtils.localeFromLanguage(language),
        })
        .pipe(
          tap((response) => {
            // set the paging for autocomplete infinite scroll
            if (response?.pagingMetadata?.hasMore) {
              this.nextCategoriesPage = {
                cursor: response.pagingMetadata.nextCursor,
                pageSize: CATEGORY_SEARCH_PAGE_SIZE,
              };
            }
          }),
          map((response) => {
            const categories = response?.category || [];
            // enforce the category type because the API will return null for vCategory
            categories.forEach((category) => (category.type = type));
            return <SearchResult>{ categories, searchTerm };
          }),
        );
    }),
    withLatestFrom(this.aggregatedResult$$),
    map(([result, newCategories]) => {
      result.categories = (newCategories ?? []).concat(result.categories);
      this.aggregatedResult$$.next(result.categories);
      if (this.isInitialFetch) {
        this.afterInitialFetch.emit();
      }
      this.isInitialFetch = false;
      return result;
    }),
  );

  constructor(private readonly categoriesApiService: CategoriesApiService) {}

  get categoryType(): CategoryType {
    return this.categoryType$$.value;
  }

  set categoryType(value: CategoryType) {
    this.categoryType$$.next(value);
  }

  fetch(searchTerm: string): void {
    this.categorySearchTerm$$.next(searchTerm);
  }

  fetchNext(): void {
    if (this.nextCategoriesPage) {
      this.categorySearchPage$$.next(this.nextCategoriesPage);
    }
  }

  getCategoryByExternalIDsAndType(categories: CategoryRequestInterface[]): Observable<CategoryInterface[]> {
    return this.language$$.pipe(
      switchMap((language) =>
        this.categoriesApiService.getCategoryByExternalIDsAndType({
          categories,
          languageAndLocale: CategoryUtils.localeFromLanguage(language),
        }),
      ),
      map((response) => response?.category || []),
    );
  }
}

function isExternalId(term: string): boolean {
  const pattern = /[_:\d]/;
  return pattern.test(term);
}

export interface FacebookPostInterface {
  profileImageUrl: string;
  profileUrl: string;
  profileName: string;
  postText: string;
  postUrl: string;
  posted: Date;
}

export class FacebookPost {
  profileImageUrl: string;
  profileUrl: string;
  profileName: string;
  postText: string;
  postUrl: string;
  posted: Date;

  constructor(p: FacebookPostInterface) {
    this.profileImageUrl =
      p.profileImageUrl || 'https://vstatic-prod.apigateway.co/snapshot-client.9223372035122227248.prod/assets/images/profile.svg';
    this.profileUrl = p.profileUrl;
    this.profileName = p.profileName || 'A Facebook User';
    this.postText = p.postText;
    this.postUrl = p.postUrl;
    this.posted = p.posted;
  }
}

<div>
  <div class="hide-mobile" class="logo">
    <div *ngIf="showPoweredByVendasta$ | async" class="powered-by-vendasta">
      <span class="powered-by-vendasta-text">{{ 'COMMON.POWERED_BY' | translate }}</span>
      <img
        class="powered-by-vendasta-logo"
        src="https://vstatic-prod.apigateway.co/snapshot-client.9223372035122227248.prod/assets/images/powered-by-us.png"
      />
    </div>
    <div *ngIf="logoUrl" class="image-container">
      <img class="logo-image" [src]="logoUrl" />
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="business-info" *ngIf="snapshotData">
    <div class="company-name">{{ snapshotData.companyName }}</div>
    <div class="company-address">
      <span *ngIf="!snapshotData.serviceAreaBusiness">
        {{ snapshotData.address }}
      </span>
      {{ snapshotData.city }}, {{ snapshotData.state }}
    </div>
  </div>
  <mat-divider></mat-divider>
  <mat-nav-list dense>
    <a
      mat-list-item
      *ngIf="!hideGrades"
      [routerLink]="buildRouterLink()"
      fragment="overall-score"
      [trackEvent]="{
        eventName: 'overall-score',
        category: 'sidebar',
        action: 'click'
      }"
      [ngClass]="{
        'mat-list-item-focus': activeNavItem === 'score'
      }"
      (click)="handleMenuItemClick()"
    >
      <mat-icon matListItemIcon *ngIf="edit"></mat-icon>
      <mat-icon matListItemIcon class="overall-score-label">equalizer</mat-icon>
      <span matListItemTitle>
        {{ 'TITLES.OVERALL_SCORE' | translate }}
      </span>
      <span
        class="overall-score"
        matListItemMeta
        *ngIf="overallScore"
        [ngClass]="{
          yellow: overallScore.score >= 50,
          green: overallScore.score >= 80
        }"
      >
        {{ overallScore.score }}%
      </span>
    </a>
    <a
      mat-list-item
      [routerLink]="buildRouterLink()"
      fragment="business-details"
      [trackEvent]="{
        eventName: 'business',
        category: 'sidebar',
        action: 'click'
      }"
      [ngClass]="{
        'mat-list-item-focus': activeNavItem === 'business'
      }"
      (click)="handleMenuItemClick()"
    >
      <mat-icon matListItemIcon *ngIf="edit"></mat-icon>
      <mat-icon matListItemIcon>business</mat-icon>
      <span matListItemTitle>{{ 'TITLES.BUSINESS_DETAILS' | translate }}</span>
    </a>
  </mat-nav-list>
  <mat-divider></mat-divider>
  <ng-container *ngIf="sections">
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <mat-nav-list dense>
      <ng-container *ngFor="let section of sections" [ngSwitch]="section">
        <ng-container *ngSwitchCase="Section.LISTINGS">
          <a
            data-testid="listings-section-navigation"
            *ngIf="snapshotService.listingSectionEnabled$ | async; else listingsNav"
            mat-list-item
            [attr.data-section]="Section.LISTINGS"
            [routerLink]="buildRouterLink()"
            fragment="listings"
            [trackEvent]="{
              eventName: 'listings',
              category: 'sidebar',
              action: 'click'
            }"
            [ngClass]="{
              'mat-list-item-focus': activeNavItem === 'listings'
            }"
            [draggable]="edit"
            (dragenter)="dragenter($event)"
            (dragstart)="dragstart($event)"
            (dragend)="dragend($event)"
            [disableRipple]="edit"
            (click)="handleMenuItemClick()"
          >
            <mat-icon matListItemIcon *ngIf="edit" class="grabber">drag_handle</mat-icon>
            <mat-icon matListItemIcon svgIcon="listings"></mat-icon>
            <span matListItemTitle>
              {{ 'TITLES.LISTINGS' | translate }}
            </span>
            <snap-grade
              matListItemMeta
              *ngIf="!hideGrades"
              [grade]="snapshotService.listingGrade$ | async"
              size="extra-small"
            ></snap-grade>
          </a>
        </ng-container>
        <ng-container *ngSwitchCase="Section.REVIEWS">
          <a
            data-testid="reviews-section-navigation"
            *ngIf="snapshotService.reviewSectionEnabled$ | async; else reviewsNav"
            mat-list-item
            [attr.data-section]="Section.REVIEWS"
            [routerLink]="buildRouterLink()"
            fragment="reviews"
            [trackEvent]="{
              eventName: 'reviews',
              category: 'sidebar',
              action: 'click'
            }"
            [ngClass]="{
              'mat-list-item-focus': activeNavItem === 'reviews'
            }"
            [draggable]="edit"
            (dragenter)="dragenter($event)"
            (dragstart)="dragstart($event)"
            (dragend)="dragend($event)"
            [disableRipple]="edit"
            (click)="handleMenuItemClick()"
          >
            <mat-icon matListItemIcon *ngIf="edit" class="grabber">drag_handle</mat-icon>
            <mat-icon matListItemIcon svgIcon="reviews"></mat-icon>
            <span matListItemTitle>
              {{ 'TITLES.REVIEWS' | translate }}
            </span>
            <snap-grade
              matListItemMeta
              *ngIf="!hideGrades"
              [grade]="snapshotService.reviewGrade$ | async"
              size="extra-small"
            ></snap-grade>
          </a>
        </ng-container>
        <ng-container *ngSwitchCase="Section.SOCIAL">
          <a
            data-testid="social-section-navigation"
            *ngIf="snapshotService.socialSectionEnabled$ | async; else socialNav"
            mat-list-item
            [attr.data-section]="Section.SOCIAL"
            [routerLink]="buildRouterLink()"
            fragment="social"
            [trackEvent]="{
              eventName: 'social',
              category: 'sidebar',
              action: 'click'
            }"
            [ngClass]="{
              'mat-list-item-focus': activeNavItem === 'social'
            }"
            [draggable]="edit"
            (dragenter)="dragenter($event)"
            (dragstart)="dragstart($event)"
            (dragend)="dragend($event)"
            [disableRipple]="edit"
            (click)="handleMenuItemClick()"
          >
            <mat-icon matListItemIcon *ngIf="edit" class="grabber">drag_handle</mat-icon>
            <mat-icon matListItemIcon svgIcon="social"></mat-icon>
            <span matListItemTitle>
              {{ 'TITLES.SOCIAL' | translate }}
            </span>
            <snap-grade
              matListItemMeta
              *ngIf="!hideGrades"
              [grade]="snapshotService.socialGrade$ | async"
              size="extra-small"
            ></snap-grade>
          </a>
        </ng-container>
        <ng-container *ngSwitchCase="Section.WEBSITE">
          <a
            data-testid="website-section-navigation"
            *ngIf="snapshotService.websiteSectionEnabled$ | async; else websiteNav"
            mat-list-item
            [attr.data-section]="Section.WEBSITE"
            [routerLink]="buildRouterLink()"
            fragment="website"
            [trackEvent]="{
              eventName: 'website',
              category: 'sidebar',
              action: 'click'
            }"
            [ngClass]="{
              'mat-list-item-focus': activeNavItem === 'website'
            }"
            [draggable]="edit"
            (dragenter)="dragenter($event)"
            (dragstart)="dragstart($event)"
            (dragend)="dragend($event)"
            [disableRipple]="edit"
            (click)="handleMenuItemClick()"
          >
            <mat-icon matListItemIcon *ngIf="edit" class="grabber">drag_handle</mat-icon>
            <mat-icon matListItemIcon svgIcon="website"></mat-icon>
            <span matListItemTitle>
              {{ 'TITLES.WEBSITE' | translate }}
            </span>
            <snap-grade
              matListItemMeta
              *ngIf="!hideGrades"
              [grade]="snapshotService.websiteGrade$ | async"
              size="extra-small"
            ></snap-grade>
          </a>
        </ng-container>
        <ng-container *ngSwitchCase="Section.ECOMMERCE">
          <ng-container *ngIf="displayEcommerceSection">
            <a
              data-testid="ecommerce-section-navigation"
              *ngIf="snapshotService.ecommerceSectionEnabled$ | async; else ecommerceNav"
              mat-list-item
              [attr.data-section]="Section.ECOMMERCE"
              [routerLink]="buildRouterLink()"
              fragment="ecommerce"
              [trackEvent]="{
                eventName: 'ecommerce',
                category: 'sidebar',
                action: 'click'
              }"
              [ngClass]="{
                'mat-list-item-focus': activeNavItem === 'ecommerce'
              }"
              [draggable]="edit"
              (dragenter)="dragenter($event)"
              (dragstart)="dragstart($event)"
              (dragend)="dragend($event)"
              [disableRipple]="edit"
              (click)="handleMenuItemClick()"
            >
              <mat-icon matListItemIcon *ngIf="edit" class="grabber">drag_handle</mat-icon>
              <mat-icon matListItemIcon svgIcon="ecommerce"></mat-icon>
              <span matListItemTitle>
                {{ 'TITLES.ECOMMERCE' | translate }}
              </span>
              <snap-grade
                matListItemMeta
                *ngIf="!hideGrades"
                [grade]="snapshotService.ecommerceGrade$ | async"
                size="extra-small"
              ></snap-grade>
            </a>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="Section.ADVERTISING">
          <a
            data-testid="advertising-section-navigation"
            *ngIf="snapshotService.advertisingSectionEnabled$ | async; else advertisingNav"
            mat-list-item
            [attr.data-section]="Section.ADVERTISING"
            [routerLink]="buildRouterLink()"
            fragment="advertising"
            [trackEvent]="{
              eventName: 'advertising',
              category: 'sidebar',
              action: 'click'
            }"
            [ngClass]="{
              'mat-list-item-focus': activeNavItem === 'advertising'
            }"
            [draggable]="edit"
            (dragenter)="dragenter($event)"
            (dragstart)="dragstart($event)"
            (dragend)="dragend($event)"
            [disableRipple]="edit"
            (click)="handleMenuItemClick()"
          >
            <mat-icon matListItemIcon *ngIf="edit" class="grabber">drag_handle</mat-icon>
            <mat-icon matListItemIcon svgIcon="advertising"></mat-icon>
            <span matListItemTitle>
              {{ 'TITLES.ADVERTISING' | translate }}
            </span>
            <snap-grade
              matListItemMeta
              *ngIf="!hideGrades"
              [grade]="snapshotService.advertisingGrade$ | async"
              size="extra-small"
            ></snap-grade>
          </a>
        </ng-container>
        <ng-container *ngSwitchCase="Section.SEO">
          <a
            data-testid="seo-section-navigation"
            *ngIf="snapshotService.seoSectionEnabled$ | async; else seoNav"
            mat-list-item
            [attr.data-section]="Section.SEO"
            [routerLink]="buildRouterLink()"
            fragment="seo"
            [trackEvent]="{
              eventName: 'seo',
              category: 'sidebar',
              action: 'click'
            }"
            [ngClass]="{
              'mat-list-item-focus': activeNavItem === 'seo'
            }"
            [draggable]="edit"
            (dragenter)="dragenter($event)"
            (dragstart)="dragstart($event)"
            (dragend)="dragend($event)"
            [disableRipple]="edit"
            (click)="handleMenuItemClick()"
          >
            <mat-icon matListItemIcon *ngIf="edit" class="grabber">drag_handle</mat-icon>
            <mat-icon matListItemIcon svgIcon="seo"></mat-icon>
            <span matListItemTitle>
              {{ 'TITLES.SEO' | translate }}
            </span>
            <snap-grade
              matListItemMeta
              *ngIf="!hideGrades"
              [grade]="snapshotService.seoGrade$ | async"
              size="extra-small"
            ></snap-grade>
          </a>
        </ng-container>
        <ng-container *ngSwitchCase="Section.TECHNOLOGY">
          <a
            data-testid="technology-section-navigation"
            *ngIf="snapshotService.technologySectionEnabled$ | async; else technologyNav"
            mat-list-item
            [attr.data-section]="Section.TECHNOLOGY"
            [routerLink]="buildRouterLink()"
            fragment="technology"
            [trackEvent]="{
              eventName: 'technology',
              category: 'sidebar',
              action: 'click'
            }"
            [ngClass]="{
              'mat-list-item-focus': activeNavItem === 'technology'
            }"
            [draggable]="edit"
            (dragenter)="dragenter($event)"
            (dragstart)="dragstart($event)"
            (dragend)="dragend($event)"
            [disableRipple]="edit"
            (click)="handleMenuItemClick()"
          >
            <mat-icon matListItemIcon *ngIf="edit" class="grabber">drag_handle</mat-icon>
            <mat-icon matListItemIcon svgIcon="technology"></mat-icon>
            <span matListItemTitle>
              {{ 'TITLES.TECHNOLOGY' | translate }}
            </span>
          </a>
        </ng-container>
      </ng-container>
    </mat-nav-list>
  </ng-container>
</div>

<ng-template #listingsNav>
  <ng-container *ngIf="edit">
    <a
      mat-list-item
      class="disabled"
      [attr.data-section]="Section.LISTINGS"
      [routerLink]="buildRouterLink()"
      fragment="listings"
      [trackEvent]="{
        eventName: 'listings',
        category: 'sidebar',
        action: 'click'
      }"
      (click)="handleMenuItemClick()"
    >
      <mat-icon matListItemIcon></mat-icon>
      <mat-icon matListItemIcon svgIcon="listings"></mat-icon>
      <span matListItemTitle>
        {{ 'TITLES.LISTINGS' | translate }}
      </span>
      <snap-grade matListItemMeta [grade]="0" size="extra-small"></snap-grade>
    </a>
  </ng-container>
</ng-template>
<ng-template #reviewsNav>
  <ng-container *ngIf="edit">
    <a
      mat-list-item
      class="disabled"
      [attr.data-section]="Section.REVIEWS"
      [routerLink]="buildRouterLink()"
      fragment="reviews"
      [trackEvent]="{
        eventName: 'reviews',
        category: 'sidebar',
        action: 'click'
      }"
      (click)="handleMenuItemClick()"
    >
      <mat-icon matListItemIcon></mat-icon>
      <mat-icon matListItemIcon svgIcon="reviews"></mat-icon>
      <span matListItemTitle>
        {{ 'TITLES.REVIEWS' | translate }}
      </span>
      <snap-grade matListItemMeta [grade]="0" size="extra-small"></snap-grade>
    </a>
  </ng-container>
</ng-template>
<ng-template #socialNav>
  <ng-container *ngIf="edit">
    <a
      mat-list-item
      class="disabled"
      [attr.data-section]="Section.SOCIAL"
      [routerLink]="buildRouterLink()"
      fragment="social"
      [trackEvent]="{
        eventName: 'social',
        category: 'sidebar',
        action: 'click'
      }"
      (click)="handleMenuItemClick()"
    >
      <mat-icon matListItemIcon></mat-icon>
      <mat-icon matListItemIcon svgIcon="social"></mat-icon>
      <span matListItemTitle>
        {{ 'TITLES.SOCIAL' | translate }}
      </span>
      <snap-grade matListItemMeta [grade]="0" size="extra-small"></snap-grade>
    </a>
  </ng-container>
</ng-template>
<ng-template #websiteNav>
  <ng-container *ngIf="edit">
    <a
      mat-list-item
      class="disabled"
      [attr.data-section]="Section.WEBSITE"
      [routerLink]="buildRouterLink()"
      fragment="website"
      [trackEvent]="{
        eventName: 'website',
        category: 'sidebar',
        action: 'click'
      }"
      (click)="handleMenuItemClick()"
    >
      <mat-icon matListItemIcon></mat-icon>
      <mat-icon matListItemIcon svgIcon="website"></mat-icon>
      <span matListItemTitle>
        {{ 'TITLES.WEBSITE' | translate }}
      </span>
      <snap-grade matListItemMeta [grade]="0" size="extra-small"></snap-grade>
    </a>
  </ng-container>
</ng-template>
<ng-template #ecommerceNav>
  <ng-container *ngIf="edit">
    <a
      mat-list-item
      class="disabled"
      [attr.data-section]="Section.ECOMMERCE"
      [routerLink]="buildRouterLink()"
      fragment="ecommerce"
      [trackEvent]="{
        eventName: 'ecommerce',
        category: 'sidebar',
        action: 'click'
      }"
      (click)="handleMenuItemClick()"
    >
      <mat-icon matListItemIcon></mat-icon>
      <mat-icon matListItemIcon svgIcon="ecommerce"></mat-icon>
      <span matListItemTitle>
        {{ 'TITLES.ECOMMERCE' | translate }}
      </span>
      <snap-grade matListItemMeta [grade]="0" size="extra-small"></snap-grade>
    </a>
  </ng-container>
</ng-template>
<ng-template #advertisingNav>
  <ng-container *ngIf="edit">
    <a
      mat-list-item
      class="disabled"
      [attr.data-section]="Section.ADVERTISING"
      [routerLink]="buildRouterLink()"
      fragment="advertising"
      [trackEvent]="{
        eventName: 'advertising',
        category: 'sidebar',
        action: 'click'
      }"
      (click)="handleMenuItemClick()"
    >
      <mat-icon matListItemIcon></mat-icon>
      <mat-icon matListItemIcon svgIcon="advertising"></mat-icon>
      <span matListItemTitle>
        {{ 'TITLES.ADVERTISING' | translate }}
      </span>
      <snap-grade matListItemMeta [grade]="0" size="extra-small"></snap-grade>
    </a>
  </ng-container>
</ng-template>
<ng-template #seoNav>
  <ng-container *ngIf="edit">
    <a
      mat-list-item
      class="disabled"
      [attr.data-section]="Section.SEO"
      [routerLink]="buildRouterLink()"
      fragment="seo"
      [trackEvent]="{
        eventName: 'seo',
        category: 'sidebar',
        action: 'click'
      }"
      (click)="handleMenuItemClick()"
    >
      <mat-icon matListItemIcon></mat-icon>
      <mat-icon matListItemIcon svgIcon="seo"></mat-icon>
      <span matListItemTitle>
        {{ 'TITLES.SEO' | translate }}
      </span>
      <snap-grade matListItemMeta [grade]="0" size="extra-small"></snap-grade>
    </a>
  </ng-container>
</ng-template>
<ng-template #technologyNav>
  <ng-container *ngIf="edit && canEnableTechnologySection">
    <a
      mat-list-item
      class="disabled"
      [attr.data-section]="Section.TECHNOLOGY"
      [routerLink]="buildRouterLink()"
      fragment="technology"
      [trackEvent]="{
        eventName: 'technology',
        category: 'sidebar',
        action: 'click'
      }"
      (click)="handleMenuItemClick()"
    >
      <mat-icon matListItemIcon></mat-icon>
      <mat-icon matListItemIcon svgIcon="technology"></mat-icon>
      <span matListItemTitle>
        {{ 'TITLES.TECHNOLOGY' | translate }}
      </span>
    </a>
  </ng-container>
</ng-template>

import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  HostListener,
  Inject,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { EnvironmentService, SessionService } from '@galaxy/core';
import {
  FooterCtaConfigInterface,
  ImageService,
  Language,
  RefreshReportService,
  SectionAvailabilityService,
  SNAPSHOT_NAME_TOKEN,
  SnapshotConfig,
  SnapshotReportService,
  UserEvent,
  WhitelabelService,
} from '@galaxy/snapshot';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { GuestService } from '@vendasta/meetings';
import { ProductAnalyticsService } from '@vendasta/product-analytics';
import { GlobalConfigEnabledSection as EnabledSection } from '@vendasta/snapshot';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of as observableOf, of, Subscription } from 'rxjs';
import { delay, distinctUntilChanged, map, shareReplay, skipWhile, switchMap, take, tap } from 'rxjs/operators';
import { FeatureFlagService, MediaQueryService, SalesPersonService } from '../services';
import { MarketplaceService } from '../services/marketplace.service';
import { ShareDialogComponent, ShareReportDialogParams } from '../share-report/dialog/dialog.component';
import { ANALYTICS_CATEGORY } from '../share-report/share-report';
import { ShareReportService } from '../share-report/share-report.service';
import { AskQuestionDialogComponent } from './ask-question-dialog/ask-question-dialog.component';
import { CustomizedFooterControlsInterface, SectionsReorderingEvent } from './interfaces';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Clipboard } from '@angular/cdk/clipboard';

declare let window: any;
declare let deployment: string; // Stamped down by vStatic

// All of the available sections
const allSections: EnabledSection[] = [
  EnabledSection.REVIEWS,
  EnabledSection.LISTINGS,
  EnabledSection.SOCIAL,
  EnabledSection.WEBSITE,
  EnabledSection.ADVERTISING,
  EnabledSection.SEO,
  EnabledSection.ECOMMERCE,
  EnabledSection.TECHNOLOGY,
];

@Component({
  selector: 'app-snapshot-report',
  templateUrl: './snapshot-report.component.html',
  styleUrls: ['./snapshot-report.component.scss'],
})
export class SnapshotReportComponent implements OnInit, OnDestroy, AfterViewInit {
  hasWebsiteData: boolean;
  edit = false;
  autosize = true;
  windowHeight: BehaviorSubject<number> = new BehaviorSubject<number>(800);
  private _activeNavItem$$: BehaviorSubject<string> = new BehaviorSubject<string>('business');
  activeNavItem$: Observable<string> = this._activeNavItem$$.asObservable();
  @ViewChild('business', { read: ElementRef, static: true }) businessElement: ElementRef;
  @ViewChild('score', { read: ElementRef }) scoreElement: ElementRef;
  @ViewChild('listings', { read: ElementRef }) listingsElement: ElementRef;
  @ViewChild('reviews', { read: ElementRef }) reviewsElement: ElementRef;
  @ViewChild('social', { read: ElementRef }) socialElement: ElementRef;
  @ViewChild('website', { read: ElementRef }) websiteElement: ElementRef;
  @ViewChild('advertising', { read: ElementRef }) advertisingElement: ElementRef;
  @ViewChild('seo', { read: ElementRef }) seoElement: ElementRef;
  @ViewChild('ecommerce', { read: ElementRef }) ecommerceElement: ElementRef;
  @ViewChild('technology', { read: ElementRef }) technologyElement: ElementRef;
  @ViewChild('snav', { read: MatSidenav }) sideNav: MatSidenav;

  public snapshotId: string;
  public businessId: string;

  localeOptions = [
    { value: 'en-us', label: 'English' },
    { value: 'cs', label: 'Czech' },
    { value: 'nl', label: 'Dutch' },
    { value: 'fr-fr', label: 'French' },
    { value: 'fr-ca', label: 'French (Canada)' },
    { value: 'pt', label: 'Portuguese' },
    { value: 'de', label: 'German' },
    { value: 'ru', label: 'Russian' },
    { value: 'es-419', label: 'Spanish (Latin America)' },
    { value: 'sv', label: 'Swedish' },
    { value: 'nn', label: 'Norwegian Nynorsk' },
    { value: 'nb-NO', label: 'Norwegian Bokmål' },
  ];

  loadingLocale$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  lastUpdated$: Observable<Date>;

  customKeywordControl = new FormControl<string>('');

  controls = new UntypedFormGroup({});
  customizedFooterConfig = new FormGroup<CustomizedFooterControlsInterface>({
    customizedFooterCtaLabel: new FormControl<string>(''),
    customizedFooterCtaTargetUrl: new FormControl<string>(''),
    customizedFooterCtaTargetProduct: new FormControl<string>(''),
  });
  private readonly controlsPristineValues: { [index: string]: any } = {};
  subscriptions: Subscription[] = [];

  sectionsEnum = EnabledSection;
  isReordering$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  allSectionsOrdered$$: BehaviorSubject<EnabledSection[]> = new BehaviorSubject<EnabledSection[]>([]);
  displayEcommerceSection$: Observable<boolean>;
  canEnableTechnologySection$ = of(true);
  sessionId$: Observable<string>;
  private readonly marketplaceService = inject(MarketplaceService);
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private _route: ActivatedRoute,
    public snapshotService: SnapshotReportService,
    private _titleService: Title,
    public whitelabel: WhitelabelService,
    public salesperson: SalesPersonService,
    protected dialog: MatDialog,
    public featureFlag: FeatureFlagService,
    public analyticsService: ProductAnalyticsService,
    public translate: TranslateService,
    public media: MediaQueryService,
    public environment: EnvironmentService,
    public formBuilder: UntypedFormBuilder,
    public sectionAvailabilityService: SectionAvailabilityService,
    public guestService: GuestService,
    public router: Router,
    public changeDetectorRef: ChangeDetectorRef,
    public shareReportService: ShareReportService,
    public translateService: TranslateService,
    public sessionService: SessionService,
    public snackbarService: SnackbarService,
    public refreshService: RefreshReportService,
    public imageService: ImageService,
    public clipboard: Clipboard,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    @Inject(SNAPSHOT_NAME_TOKEN) readonly snapshotName$: Observable<string>,
    @Inject(DOCUMENT) private _document: HTMLDocument,
  ) {
    iconRegistry.addSvgIcon(
      'reviews',
      sanitizer.bypassSecurityTrustResourceUrl(this.imageService.getIconSrc('ico-reviews.svg')),
    );
    iconRegistry.addSvgIcon(
      'listings',
      sanitizer.bypassSecurityTrustResourceUrl(this.imageService.getIconSrc('ico-listings.svg')),
    );
    iconRegistry.addSvgIcon(
      'website',
      sanitizer.bypassSecurityTrustResourceUrl(this.imageService.getIconSrc('ico-website.svg')),
    );
    iconRegistry.addSvgIcon(
      'advertising',
      sanitizer.bypassSecurityTrustResourceUrl(this.imageService.getIconSrc('ico-advertising.svg')),
    );
    iconRegistry.addSvgIcon(
      'seo',
      sanitizer.bypassSecurityTrustResourceUrl(this.imageService.getIconSrc('ico-seo.svg')),
    );
    iconRegistry.addSvgIcon(
      'social',
      sanitizer.bypassSecurityTrustResourceUrl(this.imageService.getIconSrc('ico-social.svg')),
    );
    iconRegistry.addSvgIcon(
      'ecommerce',
      sanitizer.bypassSecurityTrustResourceUrl(this.imageService.getIconSrc('ico-ecommerce.svg')),
    );
    iconRegistry.addSvgIcon(
      'technology',
      sanitizer.bypassSecurityTrustResourceUrl(this.imageService.getIconSrc('ico-technology.svg')),
    );

    this.windowHeight.next(window.innerHeight);
  }

  ngOnInit(): void {
    this.setupServices();

    this.snapshotService.snapshotId$.subscribe((snapshotId) => {
      this.snapshotId = snapshotId;
    });
    this.snapshotService.businessId$.subscribe((businessId) => {
      this.businessId = businessId;
    });

    this.sessionId$ = this.sessionService.getSessionId();

    // Prevents capturing events on the local environment
    if (typeof deployment !== 'undefined') {
      combineLatest([this.snapshotService.businessId$, this.snapshotService.getPartnerId()])
        .pipe(take(1))
        .subscribe(([businessId, partnerId]) => {
          this.analyticsService.initialize({
            environment: this.environment.getEnvironment(),
            projectUUID: '5c35ea3a-47ea-4247-96c8-b7ad4b19e187',
            postHogID: 'Or44VWcMj7H3THT3Wj2tTxDPxaW5B56Hwm7S-TcPrtQ',
            projectName: 'snapshot-client',
            businessID: businessId,
            partner: {
              pid: partnerId,
            },
          });
        });
    }

    this._route.queryParamMap
      .pipe(
        take(1),
        map((paramMap: ParamMap) => {
          if (paramMap.get('error') === 'error_claiming') {
            return 'ERRORS.ERROR_CLAIMING';
          }
          if (paramMap.get('error') === 'already_claimed') {
            return 'ERRORS.ALREADY_CLAIMED';
          }
          return '';
        }),
        skipWhile((k) => k === ''),
        switchMap((key) => this.translate.get(key)),
        skipWhile((message) => !message),
        delay(2000),
      )
      .subscribe((message) => this.snackbarService.openErrorSnack(message));

    // add controls for toggling each section
    const hideReviewSectionControl = new UntypedFormControl();
    this.controls.addControl('hideReviewSection', hideReviewSectionControl);
    this.subscribeToSectionVisibilityChanges('hideReviewSection', EnabledSection.REVIEWS, hideReviewSectionControl);

    const hideSocialSectionControl = new UntypedFormControl();
    this.controls.addControl('hideSocialSection', hideSocialSectionControl);
    this.subscribeToSectionVisibilityChanges('hideSocialSection', EnabledSection.SOCIAL, hideSocialSectionControl);

    const hideListingSectionControl = new UntypedFormControl();
    this.controls.addControl('hideListingSection', hideListingSectionControl);
    this.subscribeToSectionVisibilityChanges('hideListingSection', EnabledSection.LISTINGS, hideListingSectionControl);

    const hideWebsiteSectionControl = new UntypedFormControl();
    this.controls.addControl('hideWebsiteSection', hideWebsiteSectionControl);
    this.subscribeToSectionVisibilityChanges('hideWebsiteSection', EnabledSection.WEBSITE, hideWebsiteSectionControl);

    const hideAdvertisingSectionControl = new UntypedFormControl();
    this.controls.addControl('hideAdvertisingSection', hideAdvertisingSectionControl);
    this.subscribeToSectionVisibilityChanges(
      'hideAdvertisingSection',
      EnabledSection.ADVERTISING,
      hideAdvertisingSectionControl,
    );

    const hideSEOSectionControl = new UntypedFormControl();
    this.controls.addControl('hideSEOSection', hideSEOSectionControl);
    this.subscribeToSectionVisibilityChanges('hideSEOSection', EnabledSection.SEO, hideSEOSectionControl);

    const hideEcommerceSectionControl = new UntypedFormControl();
    this.controls.addControl('hideEcommerceSection', hideEcommerceSectionControl);
    this.subscribeToSectionVisibilityChanges(
      'hideEcommerceSection',
      EnabledSection.ECOMMERCE,
      hideEcommerceSectionControl,
    );

    const hideTechnologySectionControl = new UntypedFormControl();
    this.controls.addControl('hideTechnologySection', hideTechnologySectionControl);
    this.subscribeToSectionVisibilityChanges(
      'hideTechnologySection',
      EnabledSection.TECHNOLOGY,
      hideTechnologySectionControl,
    );

    // build form controls for each item in the review config
    const configTemplate = new SnapshotConfig({});
    for (const k in configTemplate) {
      // skip the customized footer because these properties will be handled as a group
      if (k?.startsWith?.('customizedFooter')) {
        continue;
      }
      // iterate config to dynamically build form controls
      if (k in configTemplate) {
        const control = new UntypedFormControl();
        this.controls.addControl(k, control);
        this.subscribeToConfigUpdates(k, control);
      }
    }
    this.customizedFooterConfig.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        switchMap((v) => this.snapshotService.updateConfig(v)),
      )
      .subscribe({
        next: () => {
          this.snackbarService.openSuccessSnack('EDIT_REPORT.UPDATE_CONFIG_SUCCESS');
          this.customizedFooterConfig.markAsPristine();
        },
        error: () => this.snackbarService.openErrorSnack('EDIT_REPORT.ERRORS.UPDATE_CONFIG'),
      });

    // subscribe to review config so that it can update form control values
    this.subscriptions.push(
      this.snapshotService.snapshotConfig$.subscribe((rc: any) => {
        this.loadingLocale$$.next(false);
        this.allSectionsOrdered$$.next(
          rc.enabledSections.concat(allSections.filter((s) => rc.enabledSections.indexOf(s) < 0)),
        );
        const toSet: any = {};
        for (const key in rc) {
          if (key in rc && this.controls.contains(key)) {
            toSet[key] = rc[key];
          }
        }
        toSet['hideReviewSection'] = rc.enabledSections.indexOf(EnabledSection.REVIEWS) < 0;
        toSet['hideSocialSection'] = rc.enabledSections.indexOf(EnabledSection.SOCIAL) < 0;
        toSet['hideListingSection'] = rc.enabledSections.indexOf(EnabledSection.LISTINGS) < 0;
        toSet['hideWebsiteSection'] = rc.enabledSections.indexOf(EnabledSection.WEBSITE) < 0;
        toSet['hideAdvertisingSection'] = rc.enabledSections.indexOf(EnabledSection.ADVERTISING) < 0;
        toSet['hideSEOSection'] = rc.enabledSections.indexOf(EnabledSection.SEO) < 0;
        toSet['hideEcommerceSection'] = rc.enabledSections.indexOf(EnabledSection.ECOMMERCE) < 0;
        toSet['hideTechnologySection'] = rc.enabledSections.indexOf(EnabledSection.TECHNOLOGY) < 0;
        this.controls.setValue(toSet, { emitEvent: false });
        this.storeControlsPristineValues();

        const customizedFooterCtaLabel = rc['customizedFooterCtaLabel'] ?? '';
        const customizedFooterCtaTargetUrl = rc['customizedFooterCtaTargetUrl'] ?? '';
        const customizedFooterCtaTargetProduct = rc['customizedFooterCtaTargetProduct'] ?? '';
        this.customizedFooterConfig.setValue(
          {
            customizedFooterCtaLabel,
            customizedFooterCtaTargetUrl,
            customizedFooterCtaTargetProduct,
          },
          { emitEvent: false },
        );
      }),
    );

    this.subscriptions.push(
      this.controls.controls['locale'].valueChanges
        .pipe(
          distinctUntilChanged(),
          tap(() => this.loadingLocale$$.next(true)),
        )
        .subscribe(),
    );

    this.lastUpdated$ = this.snapshotService.expiryDate$.pipe(
      take(1),
      map((expiryDate) => {
        const today = new Date();
        return expiryDate < today ? expiryDate : today;
      }),
      take(1),
    );

    // subscribe to snapshot data to set the page title
    this.subscriptions.push(
      this.snapshotService.snapshotData$.subscribe((sd) => {
        this._titleService.setTitle(sd.companyName);
      }),
    );

    // subscribe to snapshot data to set the page title
    this.subscriptions.push(
      this.whitelabel.faviconUrl$.subscribe((url) => {
        const faviconElement = this._document.getElementById('appFavicon');
        if (faviconElement) {
          faviconElement.setAttribute('href', url);
        }
      }),
    );

    this.translate.setDefaultLang('en-us');
    this.subscriptions.push(
      this.snapshotService.locale$.subscribe((locale) => {
        this.translate.use(locale);
      }),
    );

    this.displayEcommerceSection$ = this.snapshotService.snapshotId$.pipe(
      take(1),
      switchMap((id) => {
        if (id !== '') {
          return this.sectionAvailabilityService.getSectionAvailability(id);
        }
        return observableOf(false);
      }),
      take(1),
      shareReplay(1),
    );
  }

  private setupServices(): void {
    const marketplace = combineLatest([
      this.snapshotService.getPartnerId(),
      this.snapshotService.getMarketId(),
    ]).subscribe(([partnerId, marketId]) => {
      this.marketplaceService.load(partnerId, marketId);
    });
    this.subscriptions.push(marketplace);
  }

  private storeControlsPristineValues(): void {
    for (const key in this.controls.controls) {
      this.controlsPristineValues[key] = this.controls.controls[key].value;
    }
  }

  private subscribeToChanges(config: string, control: FormControl, requestFn: (value: any) => Observable<any>): void {
    control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async (v) => {
      if (v !== this.controlsPristineValues[config]) {
        try {
          await firstValueFrom(requestFn(v));
          this.controlsPristineValues[config] = v;
          Promise.resolve().then(() => control.updateValueAndValidity());
        } catch {
          Promise.resolve().then(() => control.setValue(this.controlsPristineValues[config]));
          this.snackbarService.openErrorSnack('EDIT_REPORT.ERRORS.UPDATE_CONFIG');
        }
      }
    });
  }

  private copySnapshotURLToClipboard(): void {
    const protocol = window.location.protocol.startsWith('https') ? 'https' : 'http';
    const path = window.location.pathname;
    const host = window.location.host;
    const section = window.location.hash;

    const url = `${protocol}://${host}${path}${section}`;
    const copied = this.clipboard.copy(url);
    if (copied) {
      const msg = this.translate.instant('SHARE_SNAPSHOT.LINK_COPIED_TO_CLIPBOARD');
      this.snackbarService.openSuccessSnack(msg);
    } else {
      const msg = this.translate.instant('SHARE_SNAPSHOT.LINK_COPIED_TO_CLIPBOARD_ERROR');
      this.snackbarService.openErrorSnack(msg);
    }
  }

  private subscribeToConfigUpdates(config: string, control: FormControl): void {
    this.subscribeToChanges(config, control, (v) => this.snapshotService.updateConfig({ [config]: v }));
  }

  private subscribeToSectionVisibilityChanges(config: string, section: EnabledSection, control: FormControl): void {
    this.subscribeToChanges(config, control, (v) => this.snapshotService.toggleSection(section, v));
  }

  async closeSideNavOnMobile(): Promise<void> {
    const isMobile = await firstValueFrom(this.media.mobile$);
    if (isMobile) {
      this.sideNav.close();
    }
  }

  ngAfterViewInit(): void {
    window.setTimeout(() => {
      this.setAutosizeToFalse();
    }, 2000);
  }

  setAutosizeToFalse(): void {
    // Enabling this option can cause layout thrashing by measuring the drawers on every change detection cycle.
    // was set to true as a workaround for mat-content, that didn't count margin-left properly
    this.autosize = false;
  }

  sendCustomKeywordControl($event: any): void {
    this.customKeywordControl = $event;
  }

  trackUserEvents(category: string, action: string, name: string, ctaType?: string): void {
    this.analyticsService.trackEvent(name, category, action, 0, { ctaType: ctaType });
  }

  ctaClick(label: string): void {
    this.subscriptions.push(
      this.whitelabel.allowClaimUser$
        .pipe(
          tap((claim) => {
            if (claim) {
              this.trackUserEvents('cta', 'click', 'go-to-business-center', 'claim');
              this.snapshotService.previewUrl$.subscribe((previewUrl) => {
                window.open(previewUrl, '_blank');
              });
            } else {
              this.openAskQuestionDialog(label);
            }
          }),
        )
        .subscribe(),
    );
  }

  sendHasWebsiteData(isData: boolean): void {
    this.hasWebsiteData = isData;
    this.changeDetectorRef.detectChanges();
  }

  async openAskQuestionDialog(label: string): Promise<void> {
    this.trackUserEvents('cta', 'click', label, 'ask-question');

    const isMobile = await firstValueFrom(this.media.mobile$);
    if (isMobile) {
      const dialogData = {
        data: {
          accountGroupId: this.snapshotService.businessId,
        },
        maxWidth: '100vw',
        width: '100%',
      };
      this.dialog.open(AskQuestionDialogComponent, dialogData);
    } else {
      this.dialog.open(AskQuestionDialogComponent, {
        data: {
          accountGroupId: this.snapshotService.businessId,
        },
        width: '500px',
      });
    }
  }

  handleUserEventEmitted(data: UserEvent): void {
    this.trackUserEvents(data.category, data.action, data.label, data.ctaType);
  }

  async openShareReportDialog(edit: boolean): Promise<void> {
    if (edit === false) {
      this.copySnapshotURLToClipboard();
      return;
    }

    const dialog$ = combineLatest([
      this.snapshotName$,
      this.snapshotService.snapshotData$,
      this.salesperson.salesPerson$,
      this.snapshotService.snapshotConfig$,
      this.media.mobile$,
    ]).pipe(
      map(([snapshotName, snapshotData, salesPerson, snapshotConfig, isMobile]) => {
        const data: ShareReportDialogParams = {
          snapshotName: snapshotName,
          companyName: snapshotData.companyName,
          edit: edit,
          businessId: this.snapshotService.businessId,
          partnerId: snapshotData.partnerId,
          marketId: snapshotData.marketId,
          salesPerson: salesPerson,
          enabledSections: snapshotConfig.enabledSections,
        };
        const maxWidth = isMobile ? '100vw' : '768px'; // limits the width of the dialog to 60vw of $media--desktop-medium-minimum
        return { data, maxWidth } as MatDialogConfig<ShareReportDialogParams>;
      }),
      switchMap((params) => this.dialog.open(ShareDialogComponent, params).afterClosed()),
    );
    const formData = await firstValueFrom(dialog$);
    if (formData?.sender && formData?.recipients && formData?.email) {
      try {
        await firstValueFrom(this.shareReportService.shareReport(formData.sender, formData.recipients, formData.email));
        this.analyticsService.trackEvent('send-email', ANALYTICS_CATEGORY, 'click', formData.recipients.length, {
          snapshotId: this.snapshotService.snapshotId,
        });
        this.snackbarService.openSuccessSnack('SHARE_SNAPSHOT.SUCCESS');
      } catch {
        this.snackbarService.openErrorSnack('SHARE_SNAPSHOT.ERROR');
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.windowHeight.next(event.target.innerWidth);
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const sections = this.allSectionsOrdered$$.getValue().slice();
    let element: any = null;
    let navItem = '';
    sections.reverse().forEach((section) => {
      if (navItem === '') {
        switch (section) {
          case EnabledSection.SEO:
            element = this.seoElement;
            navItem = 'seo';
            break;
          case EnabledSection.ADVERTISING:
            element = this.advertisingElement;
            navItem = 'advertising';
            break;
          case EnabledSection.LISTINGS:
            element = this.listingsElement;
            navItem = 'listings';
            break;
          case EnabledSection.REVIEWS:
            element = this.reviewsElement;
            navItem = 'reviews';
            break;
          case EnabledSection.WEBSITE:
            element = this.websiteElement;
            navItem = 'website';
            break;
          case EnabledSection.SOCIAL:
            element = this.socialElement;
            navItem = 'social';
            break;
          case EnabledSection.ECOMMERCE:
            element = this.ecommerceElement;
            navItem = 'ecommerce';
            break;
          case EnabledSection.TECHNOLOGY:
            element = this.technologyElement;
            navItem = 'technology';
            break;
          default:
            break;
        }
        if (!(section != null && element && element.nativeElement.getBoundingClientRect().top < 250)) {
          navItem = '';
        }
      }
    });
    if (navItem) {
      this._activeNavItem$$.next(navItem);
    } else if (this.scoreElement && this.scoreElement.nativeElement.getBoundingClientRect().top < 250) {
      this._activeNavItem$$.next('score');
    } else {
      this._activeNavItem$$.next('business');
    }
  }

  languageSelected(language: Language): void {
    this.loadingLocale$$.next(true);

    this.subscriptions.push(
      this.snapshotService.snapshotConfig$
        .pipe(
          take(1),
          switchMap(() => {
            return this.snapshotService.updateLanguageConfig({
              locale: language.code,
              videoStyle: language.videoStyle,
            });
          }),
        )
        .subscribe(),
    );
  }

  // TODO: remove these empty functions once this component is refactored
  openRefreshDialog(): void {
    // no-op
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleSectionsReorder(event: SectionsReorderingEvent): void {
    // no-op
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openFooterEditDialog(value: FooterCtaConfigInterface): void {
    // no-op
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}

<div
  class="notification"
  *ngIf="rollback === null"
  [ngClass]="{
    unfollowed: !notification.followed,
    link: !!notification.link,
  }"
  (mouseenter)="onHover()"
  (mouseleave)="onLeave()"
>
  <a [href]="notification.link" [target]="target" (click)="clickNotification()">
    <div class="notification-container">
      <div class="new-indicator-container">
        <div class="new-indicator" *ngIf="!notification.viewed"></div>
      </div>
      <div class="notification-body">
        <div>
          <span [innerHTML]="body"></span>
        </div>
        <div class="footer">
          <div class="event-time">{{ relativeTime }}</div>
          <atlas-chip
            *ngIf="getDisplayContext() && showContext"
            [style.background-color]="getColorForValue(getDisplayContext())"
          >
            {{ getDisplayContext() }}
          </atlas-chip>
        </div>
      </div>
    </div>
  </a>
  <button mat-icon-button color="primary" [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngIf="notification.followed" (click)="markAsUnread()">
      {{ 'NOTIFICATIONS.ACTIONS.MARK_AS_UNREAD' | translate }}
    </button>
    <button mat-menu-item *ngIf="!notification.followed" (click)="markAsRead()">
      {{ 'NOTIFICATIONS.ACTIONS.MARK_AS_READ' | translate }}
    </button>
    <ng-container *ngIf="!!setting">
      <button
        mat-menu-item
        *ngIf="setting.status === SubscriptionStatus.NOTIFICATION_STATUS_ENABLED"
        (click)="unsubscribeFrom()"
      >
        {{ 'NOTIFICATIONS.ACTIONS.TURN_OFF_NOTIFICATION' | translate }}
      </button>
      <button
        mat-menu-item
        *ngIf="setting.status === SubscriptionStatus.NOTIFICATION_STATUS_DISABLED"
        (click)="subscribeTo()"
      >
        {{ 'NOTIFICATIONS.ACTIONS.TURN_ON_NOTIFICATION' | translate }}
      </button>
    </ng-container>
  </mat-menu>
</div>

<div class="unsubscribe notification-container" *ngIf="rollback !== null">
  <div class="undo-body" *ngIf="rollback === 'subscribe'">
    {{ 'NOTIFICATIONS.UNSUBSCRIBE_TEXT' | translate }}
  </div>
  <div class="undo-body" *ngIf="rollback === 'unsubscribe'">
    {{ 'NOTIFICATIONS.SUBSCRIBE_TEXT' | translate }}
  </div>
  <div class="undo">
    <atlas-button (onClick)="emitUndo()" class="undo-button" [raised]="true">
      {{ 'NOTIFICATIONS.ACTIONS.UNDO' | translate }}
    </atlas-button>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureFlagStatusInterface, FeatureFlagService as PartnerFFService } from '@galaxy/partner';
import { SnapshotFeatureFlags, SnapshotReportService } from '@galaxy/snapshot';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';

enum FeatureFlags {
  Unset = '', // placeholder so we don't have an empty enum when no FF are set
  PrintReport = 'snap_print_button',
}

@Injectable()
export class FeatureFlagService {
  private readonly features: string[] = Object.values(FeatureFlags).filter(Boolean);
  private featuresEnabled$: Observable<FeatureFlagStatusInterface>;

  activeFeatures$: Observable<SnapshotFeatureFlags>;

  constructor(
    private readonly http: HttpClient,
    private readonly partnerFFService: PartnerFFService,
    private readonly snapshotReportService: SnapshotReportService,
  ) {
    this.setupFeatureFlags();
  }

  private setupFeatureFlags(): void {
    this.featuresEnabled$ = this.snapshotReportService.getPartnerId().pipe(
      distinctUntilChanged(),
      switchMap((partnerId) => this.fetchFeatureFlags(partnerId)),
      shareReplay(1),
    );
    this.activeFeatures$ = this.featuresEnabled$.pipe(
      map((featuresEnabled) => {
        const activeFeatures: SnapshotFeatureFlags = {
          unset: featuresEnabled[FeatureFlags.Unset],
          printReport: featuresEnabled[FeatureFlags.PrintReport],
        };
        return activeFeatures;
      }),
    );
  }

  private fetchFeatureFlags(partnerId: string): Observable<FeatureFlagStatusInterface> {
    if (this.features.length > 0) {
      return this.partnerFFService.batchGetStatus(partnerId, '', this.features);
    }
    return of({});
  }
}

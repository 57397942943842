import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InfiniteScrollTriggerComponent } from './infinite-scroll-trigger.component';
export { InfiniteScrollTriggerComponent };

export const MODULE_IMPORTS = [CommonModule];

export const MODULE_DECLARATIONS = [InfiniteScrollTriggerComponent];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [InfiniteScrollTriggerComponent],
})
export class GalaxyInfiniteScrollTriggerModule {}

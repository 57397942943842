import { Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, scan, share, shareReplay, startWith } from 'rxjs/operators';
import { WorkStateMap } from './work-state-map';

interface State<ID> {
  id: ID;
  isExpired: boolean;
}

interface Status<ID> {
  id: ID;
  isSuccess: boolean;
}

interface Result<ID, RESULT> {
  id: ID;
  result: RESULT;
}

function getById<ID, RESULT>(obs: Observable<Map<ID, RESULT>>, id: ID): Observable<RESULT> {
  return obs.pipe(
    filter((allResults) => allResults.has(id)),
    map((filteredResults) => filteredResults.get(id) as RESULT),
  ) as Observable<RESULT>;
}

/*
 * A WorkStateMap where individual units of work are started by explicitly
 * invoking the `startWork` function.
 */
export class ManualStartWorkStateMap<ID, RESULT> implements WorkStateMap<ID, RESULT> {
  private loading$$: Subject<State<ID>> = new ReplaySubject(1);
  public readonly loading$: Observable<Map<ID, any>>;
  private readonly status$$: Subject<Status<ID>> = new Subject();
  public readonly success$: Observable<Map<ID, boolean>>;
  public readonly successEvents$: Observable<Map<ID, boolean>>;
  private results$$: Subject<Result<ID, RESULT>> = new Subject();
  private resultsMap$$: Subject<Map<ID, RESULT>> = new ReplaySubject(1);
  public readonly results$: Observable<Map<ID, RESULT>> = this.resultsMap$$.pipe(shareReplay(1));

  constructor(private readonly logErrors = true) {
    this.loading$ = this.loading$$.asObservable().pipe(
      scan<State<ID>, Map<ID, any>>((prevVal, newVal) => {
        if (newVal.isExpired) {
          prevVal.delete(newVal.id);
        } else {
          prevVal.set(newVal.id, undefined);
        }
        return prevVal;
      }, new Map()),
      shareReplay(1),
    );
    this.results$$
      .asObservable()
      .pipe(
        scan<Result<ID, RESULT>, Map<ID, any>>((prevVal, newVal) => {
          prevVal.set(newVal.id, newVal.result);
          return prevVal;
        }, new Map()),
      )
      .subscribe(this.resultsMap$$);
    this.successEvents$ = this.status$$.asObservable().pipe(
      scan<Status<ID>, Map<ID, boolean>>((prevMap, status) => {
        prevMap.set(status.id, status.isSuccess);
        return prevMap;
      }, new Map()),
      share(),
    );
    const successSink = new ReplaySubject<Map<ID, boolean>>(1);
    this.successEvents$.subscribe(successSink);
    this.success$ = successSink.asObservable();
  }

  isLoading$(someId: ID): Observable<boolean> {
    return this.loading$.pipe(
      map((allWork) => allWork.has(someId)),
      startWith(false),
      distinctUntilChanged(),
    );
  }

  startWork(
    id: ID,
    work: (onFinish: (result: RESULT) => void, onFailure: () => void) => void,
    fallbackResult?: RESULT,
  ): void {
    this.loading$$.next({
      id: id,
      isExpired: false,
    });
    const onSuccess = (result: RESULT) => {
      this.handleSuccess(id, result);
    };
    const onFail = () => {
      this.handleFailure(id, fallbackResult);
    };

    const safeWork = () => {
      try {
        work(onSuccess, onFail);
      } catch (e) {
        if (this.logErrors) {
          console.error(e);
        }
        onFail();
      }
    };

    safeWork();
  }

  private handleSuccess(id: ID, result: RESULT): void {
    this.loading$$.next({
      id: id,
      isExpired: true,
    });
    this.status$$.next({
      id: id,
      isSuccess: true,
    });
    this.results$$.next({
      id: id,
      result: result,
    });
  }

  private handleFailure(id: ID, fallbackResult?: RESULT): void {
    this.loading$$.next({
      id: id,
      isExpired: true,
    });
    this.status$$.next({
      id: id,
      isSuccess: false,
    });
    if (this.fallbackResultIsProvided(fallbackResult)) {
      this.results$$.next({
        id: id,
        result: fallbackResult as RESULT,
      });
    }
  }

  private fallbackResultIsProvided(result?: RESULT): boolean {
    // TODO: Support null results, too.
    return result !== null && result !== undefined;
  }

  isSuccess$(someId: ID): Observable<boolean> {
    return getById(this.success$, someId).pipe(distinctUntilChanged());
  }

  successEvent$(someId: ID): Observable<boolean> {
    return getById(this.successEvents$, someId);
  }

  getWorkResults$(someId: ID): Observable<RESULT> {
    return getById(this.results$, someId).pipe(distinctUntilChanged(), shareReplay(1));
  }
}

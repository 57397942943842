import {
  booleanAttribute,
  Component,
  effect,
  inject,
  Input,
  input,
  OnInit,
  signal,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import {
  buffer,
  catchError,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeWhile,
  tap,
} from 'rxjs/operators';

import { FirestoreInstances } from '@angular/fire/firestore';
import { EnvironmentService } from '@galaxy/core';
import { NotificationMedium, NotificationSetting } from '@vendasta/notifications-sdk';
import { onSnapshot } from 'firebase/firestore';
import { FirestoreDB } from '../realtime/firestore';
import { getSettingFromNotification, Notification } from './notification';
import { NotificationsService } from './notifications.service';
import { SubscriptionState } from './subscription-state';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

const getFirestore = (name: string) => {
  const fsi = inject(FirestoreInstances);
  return fsi.find((fs) => fs.app.name === name);
};

@Component({
  selector: 'atlas-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  // Will show a badge beside each notification indicating which partner it came from.
  // This should only be turned on for users with access to multiple partners (like superusers).
  @Input() showContext: boolean;
  // This field should be provided on init, and updates to this field will not trigger data fetching
  @Input() partnerId: string;
  @Input() userId: string;
  readonly useNewTopBar = input(false, { transform: booleanAttribute });

  public SubscriptionState = SubscriptionState;
  public notificationsBuffer$$: BehaviorSubject<Notification[]> = new BehaviorSubject(null);
  public error$: Observable<boolean>;
  public unviewed$: Observable<number>;
  protected open$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // This forces the notifications to reload if an action has been taken, such as marking as viewed, or following a link.
  private reload$$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  // Force the settings to update once they are modified by the user.
  private reloadSettings$$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private settings$: Observable<Array<NotificationSetting>>;
  public settingsByNotification$: Observable<Record<string, NotificationSetting>>;
  private settingRollbackStates$$: BehaviorSubject<{ [key: string]: SubscriptionState }> = new BehaviorSubject({});
  private titleReg = /^\((\d+\+?)\)\s/;
  private subscriptions: Subscription[] = [];
  private firestore = getFirestore('notifications');
  private modal = inject(MatDialog);
  @ViewChild('flyout') notificationsTemplate: TemplateRef<unknown>;
  private dialogRef: MatDialogRef<unknown>;
  protected isMobile = signal(false);
  private breakPointObserver = inject(BreakpointObserver);
  public breakPoint$ = this.breakPointObserver
    .observe([Breakpoints.Handset])
    .pipe(tap((result) => this.isMobile.set(result.matches)));

  constructor(
    private notificationsService: NotificationsService,
    private envService: EnvironmentService,
    private titleService: Title,
    private readonly realtime: FirestoreDB<unknown>,
  ) {
    this.settings$ = this.reloadSettings$$.pipe(
      switchMap(() => this.notificationsService.settings$(this.partnerId)),
      catchError(() => of([])),
    );
    this.settingsByNotification$ = combineLatest([this.notificationsBuffer$$, this.settings$]).pipe(
      map(([notifications, settings]) => {
        const settingsMap = {};
        notifications.forEach((notification) => {
          settingsMap[notification.id] = getSettingFromNotification(notification, settings);
        });
        return settingsMap;
      }),
    );
    effect(() => {
      if (!this.isMobile()) {
        this.closeNotificationsModal();
      }
    });
  }

  ngOnInit(): void {
    const collectionUpdateQuery = this.realtime.newChangeQuery(
      this.firestore,
      this.envService.getEnvironment(),
      this.userId,
    );

    const collectionUpdated$ = new Observable((observer) => {
      const unsubscribe = onSnapshot(
        collectionUpdateQuery,
        (snapshot) => {
          const data = snapshot.docs.map((doc) => doc.data());
          observer.next(data);
        },
        (error) => {
          console.warn('Error loading realtime notifications', error), observer.error(error);
        },
      );
      return () => unsubscribe();
    });

    const loadNotifications$ = combineLatest([collectionUpdated$, this.reload$$]).pipe(
      switchMap(() =>
        this.notificationsService.loadNotifications$(
          this.partnerId,
          null,
          null,
          false,
          NotificationMedium.NOTIFICATION_MEDIUM_WEB,
        ),
      ),
      filter((resp) => !!resp && !!resp.notifications),
      map((resp) => resp.notifications),
      shareReplay(1),
    );

    const flushBuffer$ = this.open$$.pipe(filter((open) => open));
    const notifications$ = loadNotifications$.pipe(
      buffer(flushBuffer$),
      filter((buf) => buf.length > 0),
      map((buf) => buf[buf.length - 1]),
      tap((notifications) => this.notificationsBuffer$$.next(notifications)),
    );
    this.error$ = loadNotifications$.pipe(
      startWith(false),
      map(() => false),
      catchError(() => of(true)),
    );
    this.unviewed$ = loadNotifications$.pipe(
      map((notifications) => notifications.filter((n) => !n.viewed).length),
      tap((count) => this.setUnviewedTitle(count)),
    );

    // We need to populate the notifications maually the first time, otherwise if the user opens the tray before the
    // loading is compelete, the response will be buffered and they will only see a spinner until they close and reopen
    loadNotifications$.pipe(take(1)).subscribe((notifications) => this.notificationsBuffer$$.next(notifications));
    // Subscribing to this keeps the notifications updated in the background.
    this.subscriptions.push(notifications$.subscribe());
  }

  public open(): void {
    this.open$$.next(true);
    this.markNotificationsAsViewed();
  }

  openNotifications(): void {
    this.dialogRef = this.modal.open(this.notificationsTemplate, {
      width: '100%',
      maxWidth: '100%',
    });
  }

  public close(): void {
    this.open$$.next(false);
    this.settingRollbackStates$$.next({});
  }

  closeNotificationsModal(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  private markNotificationsAsViewed(): void {
    this.notificationsBuffer$$
      .pipe(
        filter((notifications) => !!notifications),
        take(1),
        map((notifications) => notifications.filter((n) => !n.viewed)),
        map((notifications) => notifications.map((n) => n.id)),
        takeWhile((ids) => ids.length > 0),
        switchMap((ids) => this.notificationsService.viewed$(this.partnerId, ids)),
        tap(() => this.reload$$.next(true)),
      )
      .subscribe();
  }

  public getRollbackState(notification: Notification): Observable<SubscriptionState> {
    return this.settingRollbackStates$$.pipe(
      map((states) => {
        return states[notification.id] || null;
      }),
    );
  }

  public follow(notification: Notification): void {
    if (!notification.followed) {
      this.setFollowed(notification.id, true);
    }
  }

  public setFollowed(notificationId: string, followed: boolean): void {
    this.notificationsService
      .followed$(this.partnerId, [notificationId], followed)
      .pipe(
        switchMap(() => this.notificationsBuffer$$),
        take(1),
        map((notifications) => {
          notifications.find((n) => n.id === notificationId).followed = followed;
          return notifications;
        }),
        tap((notifications) => this.notificationsBuffer$$.next(notifications)),
      )
      .subscribe(() => this.reload$$.next(true));
  }

  public updateSubscription(notificationId: string, setting: NotificationSetting, newState: SubscriptionState): void {
    this.setSubscriptionState(setting, newState)
      .pipe(
        switchMap(() => this.settingRollbackStates$$),
        catchError(() => of(null)),
        take(1),
        takeWhile((result) => result !== null),
        map((rollbackState) => ({
          ...rollbackState,
          [notificationId]:
            newState === SubscriptionState.Subscribe ? SubscriptionState.Unsubscribe : SubscriptionState.Subscribe,
        })),
        tap((rollbackState) => this.settingRollbackStates$$.next(rollbackState)),
        tap(() => this.reloadSettings$$.next(true)),
      )
      .subscribe();
  }

  public undo(notificationId: string, notificationSetting: NotificationSetting): void {
    this.settingRollbackStates$$
      .pipe(
        take(1),
        switchMap((state) => this.setSubscriptionState(notificationSetting, state[notificationId])),
        switchMap(() => this.settingRollbackStates$$),
        take(1),
        map((state) => {
          delete state[notificationId];
          return state;
        }),
        tap((state) => this.settingRollbackStates$$.next(state)),
        tap(() => this.reloadSettings$$.next(true)),
      )
      .subscribe();
  }

  private setSubscriptionState(
    setting: NotificationSetting,
    newState: SubscriptionState,
  ): Observable<Record<string, any>> {
    if (newState === SubscriptionState.Subscribe) {
      return this.notificationsService.subscribe$(this.partnerId, setting);
    } else {
      return this.notificationsService.unsubscribe$(this.partnerId, setting);
    }
  }

  private setUnviewedTitle(count: number): void {
    const title = this.titleService.getTitle();
    const matches = title.match(this.titleReg);
    if (!matches && count > 0) {
      this.titleService.setTitle('(' + count + ') ' + this.titleService.getTitle());
      return;
    }
    if (!!matches && matches.length > 1 && parseInt(matches[1], 10) !== count) {
      let replacement = '';
      if (count > 0) {
        replacement = '(' + count + ') ';
      }
      this.titleService.setTitle(title.replace(this.titleReg, replacement));
    }
  }

  public markAllAsFollowed(): void {
    this.notificationsBuffer$$
      .pipe(
        take(1),
        filter((notifications) => !!notifications),
        map((notifications) => notifications.filter((n) => !n.followed)),
        map((notifications) => notifications.map((n) => n.id)),
        takeWhile((ids) => ids.length > 0),
        switchMap((ids) => this.notificationsService.followed$(this.partnerId, ids, true)),
        switchMap(() => this.notificationsBuffer$$),
        take(1),
        map((notifications) => {
          notifications.map((n) => (n.followed = true));
          return notifications;
        }),
        tap((notifications) => this.notificationsBuffer$$.next(notifications)),
      )
      .subscribe(() => this.reload$$.next(true));
  }
}

import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { GalaxyAiIconMonochrome, GalaxyAiIconSvg, GalaxyAiIconWithBackgroundSvg } from './ai-icon-svg-string';
export { GalaxyAiIconSvg, GalaxyAiIconWithBackgroundSvg };

@Injectable({
  providedIn: 'root',
})
export class GalaxyAiIconService {
  private readonly iconRegistry = inject(MatIconRegistry);
  private readonly sanitizer = inject(DomSanitizer);

  constructor() {
    // because this service is injected as root, it will only be instantiated once
    // which means we wont need to worry about adding the same icon multiple times
    this.iconRegistry.addSvgIconLiteral('galaxy-ai-icon', this.sanitizer.bypassSecurityTrustHtml(GalaxyAiIconSvg));

    this.iconRegistry.addSvgIconLiteral(
      'galaxy-ai-icon-monochrome',
      this.sanitizer.bypassSecurityTrustHtml(GalaxyAiIconMonochrome),
    );

    this.iconRegistry.addSvgIconLiteral(
      'galaxy-ai-icon-with-background',
      this.sanitizer.bypassSecurityTrustHtml(GalaxyAiIconWithBackgroundSvg),
    );
  }

  dummyInit() {
    // If the service when injected as `private` and is marked as unused,
    // you can call this method when you inject the service into your
    // component so it will be "used" and not marked as unused.
  }
}

import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Component, Input, OnInit, inject } from '@angular/core';
import { FooterCtaConfigInterface, RouteType, TechnologySectionExistsToken } from '@galaxy/snapshot';
import { SnapshotReportComponent } from './snapshot-report.component';
import { AuthInterceptor } from '../auth/auth.interceptor';
import { OpenConfirmationModalService } from '@vendasta/galaxy/confirmation-modal';
import { EditButtonDialogComponent } from './edit-button-dialog/edit-button-dialog.component';
import { SectionsReorderingEvent } from './interfaces';
import { RoutingService } from '../app.routes';

@Component({
  selector: 'app-snapshot-report-edit',
  templateUrl: './snapshot-report.component.html',
  styleUrls: ['./snapshot-report.component.scss'],
})
export class SnapshotReportEditComponent extends SnapshotReportComponent implements OnInit {
  @Input() reloadOnInvalidSession = false;
  edit = true;
  // only allow to enable the section if the tech section entity exists
  canEnableTechnologySection$ = inject(TechnologySectionExistsToken);
  private readonly authInterceptor = inject(AuthInterceptor);
  private readonly routingService = inject(RoutingService);
  private readonly sessionExpiredModal = inject(OpenConfirmationModalService);

  private readonly config$ = this.snapshotService.snapshotConfig$;

  ngOnInit(): void {
    super.ngOnInit();
    const invalidSession$ = this.authInterceptor.validSession$.pipe(
      distinctUntilChanged(),
      filter((valid) => !valid && this.reloadOnInvalidSession),
    );
    invalidSession$.pipe(take(1)).subscribe(() => this.openSessionExpiredModal(this.snapshotId));
  }

  openRefreshDialog(): void {
    this.refreshService.openRefreshDialog();
  }

  openFooterEditDialog(value: FooterCtaConfigInterface): void {
    this.dialog.open(EditButtonDialogComponent, { data: value, width: '840px', maxWidth: '95vw' });
  }

  handleSectionsReorder(event: SectionsReorderingEvent): void {
    this.isReordering$$.next(true);
    this.config$
      .pipe(
        map((config) => event.sections.filter((section) => config.enabledSections.includes(section))),
        switchMap((reorderedSections) => this.snapshotService.updateConfig({ enabledSections: reorderedSections })),
        take(1),
        tap(() => this.isReordering$$.next(false)),
      )
      .subscribe();
    this.trackUserEvents('nav', 'drag', event.trigger);
  }

  private openSessionExpiredModal(snapshotId: string): void {
    this.sessionExpiredModal
      .openModal({
        type: 'confirm',
        title: 'SESSION_EXPIRED.TITLE',
        message: 'SESSION_EXPIRED.CONTENT',
        cancelButtonText: 'SESSION_EXPIRED.CLOSE',
        confirmButtonText: 'SESSION_EXPIRED.BUTTON',
        cancelOnEscapeKeyOrBackgroundClick: false,
        actionOnEnterKey: false,
      })
      .subscribe((shouldRefresh) => {
        if (shouldRefresh) {
          this.authInterceptor.validSession$.next(true);
          const redirectURL = this.routingService.getRoute({ snapshotId, routeType: RouteType.EditV2 });
          this.router.navigate([redirectURL]);
        }
        this.dialog.closeAll();
      });
  }
}

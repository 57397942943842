import { NgModule } from '@angular/core';
import { CoreModule } from '@galaxy/core';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { OAuth2Service } from './oauth2.service';

// We need a factory, since localStorage is not available during AOT build time.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function storageFactory(): OAuthStorage {
  return window.localStorage;
}

@NgModule({
  imports: [CoreModule, OAuthModule],
  providers: [OAuth2Service],
})
export class OAuth2Module {}

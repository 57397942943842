<div
  *ngIf="{ isMobile: media.mobile$ | async, isPrinting: media.print$ | async } as page"
  class="nav-container snapshot-mat-table-overrides flex-column"
  [class.example-is-mobile]="page.isMobile"
  xmlns="http://www.w3.org/1999/html"
>
  <app-toolbar
    [edit]="edit"
    [logoUrl]="whitelabel.logoUrl$ | async"
    [reportName]="snapshotName$ | async"
    [ctaLabel]="customizedFooterConfig.controls.customizedFooterCtaLabel.value"
    [ctaTargetUrl]="customizedFooterConfig.controls.customizedFooterCtaTargetUrl.value"
    [ctaTargetProduct]="customizedFooterConfig.controls.customizedFooterCtaTargetProduct.value"
    (askQuestionClick)="openAskQuestionDialog($event)"
    (toggleSidenavClick)="snav.toggle()"
    (trackUserEvents)="handleUserEventEmitted($event)"
    (shareReportClick)="openShareReportDialog(edit)"
  ></app-toolbar>

  <mat-sidenav-container [autosize]="autosize">
    <mat-sidenav
      #snav
      [mode]="page.isMobile ? 'over' : 'side'"
      [fixedInViewport]="page.isMobile"
      [opened]="!page.isMobile"
      [style.height.px]="windowHeight"
    >
      <app-sidebar-menu
        [activeNavItem]="activeNavItem$ | async"
        [canEnableTechnologySection]="canEnableTechnologySection$ | async"
        [displayEcommerceSection]="displayEcommerceSection$ | async"
        [edit]="edit"
        [hideGrades]="snapshotService.hideGrades$ | async"
        [overallScore]="snapshotService.overallScore$ | async"
        [loading]="isReordering$$ | async"
        [logoUrl]="whitelabel.logoUrl$ | async"
        [sections]="allSectionsOrdered$$ | async"
        [snapshotData]="snapshotService.snapshotData$ | async"
        [snapshotId]="snapshotService.snapshotId$ | async"
        (menuItemClick)="closeSideNavOnMobile()"
        (sectionsReorder)="handleSectionsReorder($event)"
      ></app-sidebar-menu>
      <app-sidebar-contact
        [salesPerson]="salesperson.salesPerson$ | async"
        [partnerName]="whitelabel.partnerName$ | async"
        (askQuestionClick)="openAskQuestionDialog($event)"
        (languageSelected)="languageSelected($event)"
      ></app-sidebar-contact>
    </mat-sidenav>
    <mat-sidenav-content>
      <app-cover-page></app-cover-page>
      <div class="report-container">
        <div *ngIf="edit" class="report-actions">
          <button mat-button color="primary">
            <a
              class=""
              [routerLink]="['/snapshot', snapshotId]"
              target="_blank"
              [trackEvent]="{
                eventName: 'view-report',
                category: 'report',
                action: 'click',
              }"
            >
              <mat-icon>remove_red_eye</mat-icon>
              <span>{{ 'EDIT_REPORT.VIEW_REPORT' | translate }}</span>
            </a>
          </button>
          <ng-container *ngIf="snapshotService.isNonPartnerConfigExpired$ | async">
            <button
              mat-button
              color="primary"
              (click)="openRefreshDialog()"
              [disabled]="refreshService.provisioningInProgress$ | async"
              [trackEvent]="{ eventName: 'refresh-report', category: 'report', action: 'click' }"
            >
              <mat-icon>refresh</mat-icon>
              <span>{{ 'EDIT_REPORT.REFRESH' | translate }}</span>
            </button>
          </ng-container>
        </div>
        <div class="sections-container flex-column align-start-stretch default-gap">
          <div
            class="customization-actions flex-column align-start-stretch default-gap"
            *ngIf="snapshotService.snapshot$ | async"
          >
            <ng-container *ngIf="edit">
              <app-customize-report
                [hideControls]="this.controls"
                [localeOptions]="localeOptions"
                [expired]="snapshotService.isNonPartnerConfigExpired$ | async"
              ></app-customize-report>

              <app-schedule-meeting
                [hideControl]="this.controls.controls['hideScheduleMeetingOption']"
                [isPartnerConfig]="snapshotService.isPartnerConfig$ | async"
              ></app-schedule-meeting>

              <app-customize-primary-action [controls]="customizedFooterConfig"></app-customize-primary-action>

              <snap-custom-keyword
                data-testid="custom-keyword"
                [customKeyword]="this.customKeywordControl"
                [isExpired]="snapshotService.isNonPartnerConfigExpired$ | async"
              ></snap-custom-keyword>

              <snap-competitors
                (userEvent)="handleUserEventEmitted($event)"
                [selectControl]="this.controls.controls['competitionAnalysis']"
              ></snap-competitors>
            </ng-container>

            <ng-container *ngIf="snapshotService.isLessThan24HoursOld$ | async">
              <glxy-alert type="tip">
                <span [innerHTML]="'SNAPSHOT_STATUS.NOT_READY' | translate | iTrustThisHtml"></span>
                <span class="created-date">
                  {{ 'COMMON.CREATED' | translate }}
                  {{ (snapshotService.snapshot$ | async)?.created | date: 'longDate' }}
                </span>
              </glxy-alert>
            </ng-container>

            <div>
              <div *ngIf="lastUpdated$ | async as lastUpdated" class="last-updated flex align-start-center">
                <span>{{ 'VIEW_REPORT.LAST_UPDATED' | translate }}</span>
                <span>{{ lastUpdated | date }}</span>
              </div>

              <ng-container *ngIf="whitelabel.whitelabel$ | async as config">
                <app-call-to-action
                  [config]="config"
                  [salesperson]="salesperson.salesPerson$ | async"
                  [customHtml]="config.useCustomSnapshotHeader ? config.customSnapshotHeader : ''"
                  (ctaClick)="ctaClick('header')"
                ></app-call-to-action>
              </ng-container>
            </div>
          </div>

          <ng-container *ngIf="this.snapshotService.snapshotConfig$ | async as snapshotConfig">
            <div class="page-break"></div>
            <snap-overall-score #score id="overall-score" [edit]="edit"></snap-overall-score>
            <div class="print-spacing"></div>
            <snap-business-details
              #business
              id="business-details"
              [edit]="edit"
              (askQuestionClick)="openAskQuestionDialog($event)"
            ></snap-business-details>

            <div class="sections flex-column" [class.default-gap]="!page.isPrinting">
              <ng-container *ngIf="allSectionsOrdered$$ | async as allSectionsOrdered">
                <ng-container *ngFor="let section of allSectionsOrdered; let last = last" [ngSwitch]="section">
                  <ng-container *ngSwitchCase="sectionsEnum.LISTINGS">
                    <ng-container *ngIf="!this.controls.controls['hideListingSection'].value || edit">
                      <div class="page-break"></div>
                      <snap-listing-section
                        data-testid="listings-section"
                        #listings
                        id="listings"
                        [edit]="edit"
                        [hideControl]="this.controls.controls['hideListingSection']"
                        (sectionFooterClick)="openAskQuestionDialog('listings-footer')"
                        (sectionFooterEditClick)="openFooterEditDialog($event)"
                      ></snap-listing-section>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="sectionsEnum.REVIEWS">
                    <ng-container *ngIf="!this.controls.controls['hideReviewSection'].value || edit">
                      <div class="page-break"></div>
                      <snap-review-section
                        data-testid="reviews-section"
                        #reviews
                        id="reviews"
                        [edit]="edit"
                        [hideControl]="this.controls.controls['hideReviewSection']"
                        (sectionFooterClick)="openAskQuestionDialog('reviews-footer')"
                        (sectionFooterEditClick)="openFooterEditDialog($event)"
                      ></snap-review-section>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="sectionsEnum.SOCIAL">
                    <ng-container *ngIf="!this.controls.controls['hideSocialSection'].value || edit">
                      <div class="page-break"></div>
                      <snap-social-section
                        data-testid="social-section"
                        #social
                        id="social"
                        [edit]="edit"
                        [hideControl]="this.controls.controls['hideSocialSection']"
                        (sectionFooterClick)="openAskQuestionDialog('social-footer')"
                        (sectionFooterEditClick)="openFooterEditDialog($event)"
                      ></snap-social-section>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="sectionsEnum.WEBSITE">
                    <ng-container *ngIf="!this.controls.controls['hideWebsiteSection'].value || edit">
                      <div class="page-break"></div>
                      <snap-website-section
                        data-testid="website-section"
                        #website
                        id="website"
                        [edit]="edit"
                        [hideControl]="this.controls.controls['hideWebsiteSection']"
                        (sectionFooterClick)="openAskQuestionDialog('website-footer')"
                        (sectionFooterEditClick)="openFooterEditDialog($event)"
                        (hasWebsiteData)="sendHasWebsiteData($event)"
                      ></snap-website-section>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="sectionsEnum.ECOMMERCE">
                    <ng-container *ngIf="displayEcommerceSection$ | async">
                      <ng-container *ngIf="!this.controls.controls['hideEcommerceSection'].value || edit">
                        <div class="page-break"></div>
                        <snap-ecommerce-section
                          data-testid="ecommerce-section"
                          #ecommerce
                          id="ecommerce"
                          [edit]="edit"
                          [hideControl]="this.controls.controls['hideEcommerceSection']"
                          [isPartnerConfig]="snapshotService.isPartnerConfig$ | async"
                          (sectionFooterClick)="openAskQuestionDialog('ecommerce-footer')"
                          (sectionFooterEditClick)="openFooterEditDialog($event)"
                        ></snap-ecommerce-section>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="sectionsEnum.ADVERTISING">
                    <ng-container *ngIf="whitelabel.whitelabel$ | async as config">
                      <ng-container *ngIf="!this.controls.controls['hideAdvertisingSection'].value || edit">
                        <div class="page-break"></div>
                        <snap-advertising-section
                          data-testid="advertising-section"
                          #advertising
                          id="advertising"
                          [edit]="edit"
                          [hideControl]="this.controls.controls['hideAdvertisingSection']"
                          [showPPC]="config.showPpc"
                          [showRetargeting]="config.showRemarketing"
                          (sectionFooterClick)="openAskQuestionDialog('advertising-footer')"
                          (sectionFooterEditClick)="openFooterEditDialog($event)"
                        ></snap-advertising-section>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="sectionsEnum.SEO">
                    <ng-container *ngIf="!this.controls.controls['hideSEOSection'].value || edit">
                      <div class="page-break"></div>
                      <snap-seo-section
                        data-testid="seo-section"
                        #seo
                        id="seo"
                        [edit]="edit"
                        [hideControl]="this.controls.controls['hideSEOSection']"
                        (sectionFooterClick)="openAskQuestionDialog('seo-footer')"
                        (sectionFooterEditClick)="openFooterEditDialog($event)"
                        (customKeywordControl)="sendCustomKeywordControl($event)"
                      ></snap-seo-section>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="sectionsEnum.TECHNOLOGY">
                    <ng-container *ngIf="!this.controls.controls['hideTechnologySection'].value || edit">
                      <div class="page-break"></div>
                      <snap-technology-section
                        data-testid="technology-section"
                        #technology
                        id="technology"
                        [edit]="edit"
                        [hideControl]="this.controls.controls['hideTechnologySection']"
                        (sectionFooterClick)="openAskQuestionDialog('technology-footer')"
                        (sectionFooterEditClick)="openFooterEditDialog($event)"
                      ></snap-technology-section>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="whitelabel.whitelabel$ | async as config">
            <app-call-to-action
              [config]="config"
              [salesperson]="salesperson.salesPerson$ | async"
              [customHtml]="config.useCustomSnapshotHeader ? config.customSnapshotHeader : ''"
              (ctaClick)="ctaClick('footer')"
            ></app-call-to-action>
          </ng-container>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

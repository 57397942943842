import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap, first } from 'rxjs/operators';
import { SessionService } from './session.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private inj: Injector) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!req.withCredentials) {
      return next.handle(req);
    }
    const auth = this.inj.get(SessionService); // Work around for cyclic dependency with app config service
    return auth.getSessionId().pipe(
      first(),
      switchMap((sessionId) => {
        if (sessionId) {
          return next.handle(req.clone({ headers: req.headers.set('Authorization', `Bearer ${sessionId}`) }));
        }
        return next.handle(req);
      }),
    );
  }
}

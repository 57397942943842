import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ManualStartWorkStateMap } from './manual-start-work-state-map';
import { WorkStateMap } from './work-state-map';

/**
 * WorkStateMap which allows observables to be supplied as the work.  The WSM will
 * attempt to resolve a single result form the observable.  If it succeeds, the
 * map will use its results and update accordingly.  Likewise, if it fails it
 * will update its statuses.
 *
 * To start work, provide a promise like:
 * ```typescript
 * const wsm = new ObservableWorkStateMap<string, boolean>();
 * wsm.startWork('foo', myObservable$);
 * ```
 */
export class ObservableWorkStateMap<ID, RESULT> implements WorkStateMap<ID, RESULT> {
  private delegate = new ManualStartWorkStateMap<ID, RESULT>(this.logErrors);

  constructor(private readonly logErrors = true) {}

  startWork(someId: ID, work: Observable<RESULT>, fallbackValue?: RESULT): void {
    this.delegate.startWork(
      someId,
      (onFinish, onFailure) => {
        work.pipe(take(1)).subscribe(onFinish, (err) => {
          if (this.logErrors) {
            console.error(err);
          }
          onFailure();
        });
      },
      fallbackValue,
    );
  }

  getWorkResults$(someId: ID): Observable<RESULT> {
    return this.delegate.getWorkResults$(someId);
  }

  isLoading$(someId: ID): Observable<boolean> {
    return this.delegate.isLoading$(someId);
  }

  isSuccess$(someId: ID): Observable<boolean> {
    return this.delegate.isSuccess$(someId);
  }

  successEvent$(someId: ID): Observable<boolean> {
    return this.delegate.successEvent$(someId);
  }
}
